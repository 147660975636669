import React, { useState } from 'react'
import Header from '../../components/header';
import SalaryAdvancePaymentTable from './SalaryAdvancePages/payment';
import { SalaryAdvanceRequestTable } from './SalaryAdvancePages/request';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

const SalaryAdvancePage = () => {

  const {is_admin} = useSelector((state) => state.auth);

  const [tab, setTab] = useState("Requests");

  const navigate = useNavigate();

  const handleNavigateToRequestSalaryAdvancePage = () => {
    navigate("/salary-advance/create-request");
  }

  return (
    <>
      <Header
        text="Salary Advance"
        tabs={
          is_admin && (<div className="flex gap-[16px] mt-[16px]">
            <p
              className={
                tab === "Payments"
                  ? "font-semibold text-sm leading-[18px] text-primary underline underline-offset-[4px] decoration-solid decoration-2 cursor-pointer"
                  : "font-semibold text-sm leading-[18px] text-[#96A397] hover:text-primary hover:underline hover:underline-offset-[4px] hover:decoration-solid hover:decoration-2 cursor-pointer"
              }
              onClick={() => setTab("Payments")}
            >
              Payments
            </p>
            <p
              className={
                tab === "Requests"
                  ? "font-semibold text-sm leading-[18px] text-primary underline underline-offset-[4px] decoration-solid decoration-2 cursor-pointer"
                  : "font-semibold text-sm leading-[18px] text-[#96A397] hover:text-primary hover:underline hover:underline-offset-[4px] hover:decoration-solid hover:decoration-2 cursor-pointer"
              }
              onClick={() => setTab("Requests")}
            >
              Requests
            </p>
          </div>)}
    
      >
       {!is_admin && <div className="">
            <button
                type="submit"
                disabled="" //hover color is #24752B
                className="bg-primary disabled:bg-[#ABB6AC] border-[1px] rounded-lg w-full py-2.5 px-5 text-white border-primary font-medium hover:bg-[#24752B] hover:text-bold hover:text-white transition-all text-sm"
                onClick={handleNavigateToRequestSalaryAdvancePage}
                >
                  Request Advance               
            </button>
        </div>}
      </Header>
      {tab === "Payments" &&
        (
          <SalaryAdvancePaymentTable />
        )}

      {tab === 'Requests' && (
        <SalaryAdvanceRequestTable />
      )}
    </>
  );
}

export default SalaryAdvancePage;