import React, { useState, useEffect, useCallback, useMemo } from "react"
import LoadingStateComponent from "../../../components/Empty State/loadingstate"
import { useDispatch, useSelector } from "react-redux";
import { PaymentTable } from "../../../redux/SalaryAdvance/advanceAction";
import DrawerComponent from "../../../components/Drawer/drawer";
import moment from "moment";
import ModalComponent from "../../../components/Modals/modal";
import { updateSalaryAdvance } from "../../../redux/SalaryAdvance/advanceAction";
import { clearUpdateRequestStatus } from "../../../redux/SalaryAdvance/advance";
import PaginationComponent from "../../../components/Pagination/Pagination";
import EmptyStateComponent from "../../../components/Empty State/emptystate";

export const SalaryAdvanceRequestTable = () => {

    const dispatch = useDispatch();
    const [typeID, setTypeID] = useState(1);

    const { loading, payments, is_updated_success, total } = useSelector((state) => state?.advance);
    const { is_admin } = useSelector((state) => state.auth);
    const [showDrawer, setShowDrawer] = useState(false);
    const [singleRequest, setSingleRequest] = useState({});
    const [showAdvanceUpdateModal, setShowAdvanceUpdateModal] = useState(false);
    const [updateStatus, setUpdateStatus] = useState("");
    const [rejectionReason, setRejectionReason] = useState("")


    //pagination
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);

    const onPageSizeChange = (pageSize) => {
        setPageSize(pageSize);
        dispatch(PaymentTable({ approval_status: requestTypes[typeID].tag, page: currentPage, page_size: pageSize }));
    };



    const requestTypes = useMemo(() => [
        {
            id: 1,
            title: 'All',
            tag: 'all'
        },
        {
            id: 2,
            title: 'Pending',
            tag: 'PENDING'
        },
        {
            id: 3,
            title: 'Approved',
            tag: 'APPROVED'
        },
        {
            id: 4,
            title: 'Rejected',
            tag: 'REJECTED'
        },
    ], []);

    const textContent = (id) => {
        let res;
        switch (id) {
            case 1:
                res = 'Oops! No request available'
                break;
            case 2:
                res = 'Oops! No pending request(s) available.'
                break;
            case 3:
                res = 'Oops! No approved request(s) available.'
                break;
            case 4:
                res = 'Oops! No rejected request(s) available.'
                break;
            default:
        }
        return res;
    }


    const toggleType = useCallback((id, tag) => {
        setTypeID(id)
        switch (id) {
            case 1:
                dispatch(PaymentTable({ page: 1, page_size: pageSize }))
                break;
            case 2:
                dispatch(PaymentTable({ approval_status: tag, page: 1, page_size: pageSize }))
                break;
            case 3:
                dispatch(PaymentTable({ approval_status: tag, page: 1, page_size: pageSize }))
                break;
            case 4:
                dispatch(PaymentTable({ approval_status: tag, page: 1, page_size: pageSize }))
                break;
            case 5:
                dispatch(PaymentTable({ approval_status: tag, page: 1, page_size: pageSize }))
                break;
            default:
                dispatch(PaymentTable({ page: 1, page_size: pageSize }))
                break;
        }
    }, [dispatch, pageSize])

    const getPaymentStatusBg = (val) => {
        let result;
        switch (val) {
            case 'FAILED':
                result = 'error-badge'
                break
            case 'REJECTED':
                result = 'error-badge'
                break
            case 'PROCESSING':
                result = 'dispatched-badge'
                break
            case 'COMPLETED':
                result = 'success-badge'
                break
            case 'APPROVED':
                result = 'success-badge'
                break
            case 'PENDING':
                result = 'pending-badge'
                break
            case 'DONE':
                result = 'done-badge'
                break
            case 'PAY':
                result = 'pay-badge'
                break
            default:
                result = 'pending-badge'
                break
        }

        return result
    }

    const getPaymentStatusText = (val) => {
        let result
        switch (val) {
            case 'PROCESSING':
                result = 'Processing'
                break
            case 'FAILED':
                result = 'Failed'
                break
            case 'REJECTED':
                result = 'Rejected'
                break
            case 'PENDING':
                result = 'Pending'
                break
            case 'COMPLETED':
                result = 'Completed'
                break
            case 'APPROVED':
                result = 'Approved'
                break
            default:
                result = 'Pending'
                break
        }
        return result
    }

    const handleDrawer = (item) => {
        setSingleRequest(item);
        setShowDrawer(!showDrawer);
    }

    const handleUpdateSalaryAdvanceStatus = () => {
        dispatch(updateSalaryAdvance({ id: singleRequest?.id, approval_status: updateStatus === "Approve" ? "APPROVED" : "REJECTED", remark: rejectionReason }))
        setShowAdvanceUpdateModal(false)
        setUpdateStatus("")
    }

    useEffect(() => {
        if (is_updated_success) {
            setTimeout(() => {
                dispatch(clearUpdateRequestStatus())
                toggleType(typeID, requestTypes[typeID - 1].tag)
            }, 500);
        }
    }, [dispatch, is_updated_success, typeID, toggleType, requestTypes])

    useEffect(() => {
        toggleType(typeID, requestTypes[typeID - 1].tag)
    }, [dispatch, requestTypes, toggleType, typeID])

    return (
        <>
            <div className="mt-4 bg-white rounded-[8px]">
                {/* tabs */}
                <ul className="flex p-4 mt-5 gap-5 items-center">
                    {requestTypes.map((item) => (
                        <li
                            key={item.id}
                            onClick={() => toggleType(item.id, item.tag)}
                            className={
                                typeID === item.id
                                    ? 'flex gap-1 items-center border-b-2 border-primary pb-1 cursor-pointer'
                                    : 'flex gap-2 items-center pb-1 cursor-pointer'
                            }
                        >
                            <p
                                className={
                                    typeID === item.id
                                        ? 'text-primary text-sm font-semibold'
                                        : 'text-[#96A397] text-sm font-semibold'
                                }
                            >
                                {item.title}
                            </p>
                        </li>
                    ))}
                </ul>
                {/* Table  */}
                <div className="overflow-x-auto relative">
                    <table className="w-full text-left">
                        <thead className="text-primary text-sm border-b-[#F7F7F7] border-b-2">
                            <tr>
                                <th scope="col" className="px-4 py-4 font-semibold ">
                                    Date
                                </th>
                                <th scope="col" className="px-4 py-4 font-semibold">
                                    Amount Requested
                                </th>
                                {is_admin && (<th scope="col" className="px-4 py-4 font-semibold">
                                    Employee ID
                                </th>)}
                                {is_admin && (<th scope="col" className="px-4 py-4 font-semibold">
                                    Employee Name
                                </th>)}
                                <th scope="col" className="px-4 py-4 font-semibold">
                                    Reason for Request
                                </th>
                                <th scope="col" className="px-4 py-4 font-semibold">
                                    Approval Status
                                </th>
                                {!is_admin && <th scope="col" className="px-4 py-4 font-semibold">
                                    Payment Status
                                </th>}
                            </tr>
                        </thead>
                        {!loading && (
                            <tbody>
                                {payments.length > 0 && payments.map((item) => (
                                    <tr
                                        key={item.id}
                                        onClick={() => { handleDrawer(item) }}
                                        className="bg-white text-sm cursor-pointer hover:bg-[#EDF7EE]"
                                    >

                                        <td className="py-4 px-4 text-[#5C715E]">
                                            {item?.date_created ? moment(item?.date_created).format("MM/DD/YYYY") : "N/A"}
                                        </td>
                                        <td className="py-4 px-4 text-[#5C715E]">
                                            {item?.amount ? item.amount : "N/A"}
                                        </td>
                                        {is_admin && (<td className="py-4 px-4 text-[#5C715E]">
                                            {item?.employee?.employee_id ? item.employee?.employee_id : "N/A"}
                                        </td>)}
                                        {is_admin && (<td className="py-4 px-4 text-[#5C715E]">
                                            {item?.employee?.employee_name ? item.employee?.employee_name : "N/A"}
                                        </td>)}
                                        <td className="py-4 px-4 text-[#5C715E]">
                                            {item?.request_reason ? `${item.request_reason?.substring(0, 50)}...` : "N/A"}
                                        </td>
                                        <td className="py-4 px-4 text-[#5C715E]">
                                            <div className="flex">
                                                <div className={getPaymentStatusBg(item?.approval_status)}>
                                                    <p>{getPaymentStatusText(item?.approval_status)}</p>
                                                </div>
                                            </div>
                                        </td>
                                        {!is_admin && <td className="py-4 px-4 text-[#5C715E]">
                                            <div className="flex">
                                                <div className={getPaymentStatusBg(item?.payment_status)}>
                                                    <p>{getPaymentStatusText(item?.payment_status)}</p>
                                                </div>
                                            </div>
                                        </td>}
                                    </tr>
                                ))
                                }
                            </tbody>
                        )}
                    </table>
                    {loading &&
                        <LoadingStateComponent />
                    }
                </div>
            </div>
            {!loading && payments.length < 1 && (
                <EmptyStateComponent textContent={textContent(typeID)} />
            )}
            {!loading && payments.length > 0 ? (
                <PaginationComponent
                    page={currentPage}
                    onPageChange={(page) => setCurrentPage(page)}
                    pageSize={pageSize}
                    totalItems={total}
                    pageSizeShow
                    onPageSizeChange={(pageSize) => onPageSizeChange(pageSize)}
                />
            ) : ("")}
            <DrawerComponent
                show={showDrawer}
                showDrawer={() => setShowDrawer(false)}
                title="Salary Advance Request Summary">
                <div className="mt-6 space-y-6">
                    <div className="flex items-center flex-col space-y-2 ">
                        <h2 className="text-[#344335] text-3xl font-semibold font-['Urbanist'] leading-9">{singleRequest?.amount}</h2>
                        <div className="flex items-center gap flex-row">
                            <p className="text-primary text-sm font-semibold">{singleRequest?.employee?.employee_name}</p>
                            <p className="text-[#5c715e] text-sm font-medium font-['Urbanist'] leading-[18px]">{`${" "}| ${singleRequest?.employee?.employee_id}`}</p>
                        </div>
                    </div>
                    <div className="flex flex-row justify-between">
                        <h6 className="text-xs text-secondary1">Date</h6>
                        <p className="font-medium text-secondary1 text-sm mt-1">
                            {singleRequest && singleRequest?.date_created ? moment(singleRequest.date_created).format("MM/DD/YYYY hh:mm A") : "N/A"}
                        </p>
                    </div>
                    <div className="flex flex-row justify-between ">
                        <h6 className="text-xs font-medium  text-secondary1">Amount</h6>
                        <p className="font-medium text-secondary1 text-xs mt-1">
                            {singleRequest && singleRequest?.amount ? (singleRequest?.amount).toLocaleString() : "N/A"}
                        </p>
                    </div>
                    <div className="flex flex-row justify-between">
                        <h6 className="text-xs font-medium  text-secondary1">Reason for request</h6>
                        <p className={`font-medium  text-secondary1 ${singleRequest?.request_reason?.length > 100 ? 'w-[300px]' : ""} text-xs text-justify`}>
                            {singleRequest && singleRequest?.request_reason ? singleRequest?.request_reason : "N/A"}
                        </p>
                    </div>
                    <div className="flex flex-row justify-between">
                        <h6 className="text-xs font-medium  text-secondary1">Approval Status</h6>
                        <p className="font-medium text-secondary1 text-xs">
                            {singleRequest && singleRequest?.approval_status ? singleRequest.approval_status : "N/A"}
                        </p>
                    </div>
                    <div className="flex justify-between">
                        <h6 className="text-xs font-medium  text-secondary1">Payment Status</h6>
                        <p className="font-medium text-secondary1 text-xs">
                            {singleRequest && singleRequest?.status ? singleRequest.status : "N/A"}
                        </p>
                    </div>
                    {is_admin && (singleRequest?.approval_status === "PENDING")    &&
                        <div className={`mt-28 flex justify-between gap-6`}>
                            {singleRequest?.approval_status === "PENDING" && <button className="px-5 py-3 bg-[#b92020] rounded-lg justify-center items-center gap-2 inline-flex" onClick={() => { setShowAdvanceUpdateModal(true); setUpdateStatus("Reject") }}>
                                <p className="text-white text-xs font-medium font-['Urbanist'] leading-[18px]">Reject Salary Advance Request</p>
                            </button>}
                            <button className={`px-5 py-3 bg-[#2b8c34] rounded-lg items-center gap-2 inline-flex justify-center`}>
                                <p className="text-white text-xs font-medium leading-[18px]" onClick={() => { setShowAdvanceUpdateModal(true); setUpdateStatus("Approve") }}>Approve Salary Advance Request</p>
                            </button>
                        </div>}
                </div>
            </DrawerComponent>
            <ModalComponent show={showAdvanceUpdateModal} showModal={() => setShowAdvanceUpdateModal(false)} title={`${updateStatus} Salary Advance Request`} subtitle={""}>
                {/* <div className="">
                    {`Are you sure you sure you want to ${updateStatus.toLowerCase()} the salary advance request of ${singleRequest?.amount} from ${singleRequest?.employee?.employee_name}?`}
                </div> */}
                <div className="mt-2"><span className="text-[#5c715e] text-sm font-medium font-['Urbanist'] leading-tight">Are you sure you want to approve the salary advance request of </span><span className="text-[#5c715e] text-sm font-bold font-['Urbanist'] leading-tight">{" "}{singleRequest?.amount}{" "}</span><span className="text-[#5c715e] text-sm font-medium font-['Urbanist'] leading-tight">{" "}from</span><span className="text-[#5c715e] text-sm font-bold font-['Urbanist'] leading-tight">{" "}{singleRequest?.employee?.employee_name}</span><span className="text-[#5c715e] text-sm font-medium font-['Urbanist'] leading-tight">?</span></div>
                {
                    updateStatus === "Reject" && <div className="w-full space-y-2">
                        <p className="text-sm text-secondary font-medium">Reason for Rejection</p>
                        <textarea
                            id="rejection_reason"
                            name="rejection_reason"
                            placeholder="Kindly type out the reason why you want to request for salary advance"
                            onChange={(e) => setRejectionReason(e.target.value)}
                            className="outline-0 h-[140px] bg-[#F7F7F7] rounded-[8px] border border-[#96A397] mt-[4px] py-[12px] px-[14px] w-full font-medium text-sm text-secondary"
                        ></textarea>
                    </div>
                }
                <div className="pt-[32px] flex justify-end gap-[40px] items-center mb-[12px]">
                    <button
                        className="text-sm text-medium leading-5 text-[#5C715E]"
                        onClick={() => { handleUpdateSalaryAdvanceStatus(); setShowAdvanceUpdateModal(false) }}>No</button>
                    <button
                        className={`outline-0 ${updateStatus === "Approve" ? "bg-primary" : "bg-[#b92020]"} rounded-[8px] w-[62px] h-[40px] text-sm text-medium leading-5 text-white`}
                        onClick={() => { handleUpdateSalaryAdvanceStatus(); setShowAdvanceUpdateModal(false) }}
                    >
                        Yes
                    </button>

                </div>
            </ModalComponent>
        </>
    )
}