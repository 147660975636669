import React, { useState } from "react";
import Header from "../../../components/header";
// import SearchComponent from "../../../components/search";
// import filterIcon from "../../../assets/filter_list.svg";
import DrawerComponent from "../../../components/Drawer/drawer";
// import { debounce } from "lodash";
import ModalComponent from "../../../components/Modals/modal";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import {
  handleMakeComplaint,
  retrieveMonthlyPayslips,
  retrievePayslip, downloadPayslip
} from "../../../redux/Payslip/payslipAction";
import moment from "moment";
import { Form, Formik, Field, ErrorMessage } from "formik";
import { complaintValidator } from "../../../validationSchema/validator"
import searchIcon from "../../../assets/search-green.svg"
import PaginationComponent from "../../../components/Pagination/Pagination";
import LoadingStateComponent from "../../../components/Empty State/loadingstate";

const EmployeeDBPayslipPage = () => {
  const dispatch = useDispatch();

  const { loading, payslips, payslip, total } = useSelector((state) => state.payslip);

  //pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const onPageSizeChange = (page) => {
    setPageSize(page)
  }

  const onPageChange = (val) => {
    const { page } = val
    setCurrentPage(page)
    dispatch(retrieveMonthlyPayslips({
      page: page,
      page_size: pageSize
    })
    )
  }
  // const [searchValue, setSearchValue] = useState("");
  // // handle search
  // const handleSearch = useMemo(
  //   () =>
  //     debounce((val) => {
  //       //  api to dispatch and make the actions for search
  //       console.log(val);
  //     }, 750),
  //   []
  // );

  // const handleChange = useCallback(
  //   (e) => {
  //     let val = e.target.value;
  //     setSearchValue(val);
  //     handleSearch(val);
  //   },
  //   [handleSearch]
  // );


  //payment status on payslip table
  const getPaymentStatusBg = (val) => {
    let result;
    switch (val) {
      case 'FAILED':
        result = 'error-badge'
        break
      case 'PROCESSING':
        result = 'dispatched-badge'
        break
      case 'COMPLETED':
        result = 'success-badge'
        break
      case 'PENDING':
        result = 'pending-badge'
        break
      case 'DONE':
        result = 'done-badge'
        break
      case 'PAY':
        result = 'pay-badge'
        break
      default:
        result = 'dispatched-badge'
        break
    }
    return result
  }

  const getPaymentStatusText = (val) => {
    let result
    switch (val) {
      case 'PROCESSING':
        result = 'Processing'
        break
      case 'FAILED':
        result = 'Failed'
        break
      case 'PENDING':
        result = 'Pending'
        break
      case 'COMPLETED':
        result = 'Completed'
        break
      default:
        result = 'Processing'
        break
    }
    return result
  }


  //open drawer
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  //close drawer
  const closePayslip = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  const toggleRequest = (id) => {
    dispatch(
      retrievePayslip({
        id: id
      })
    );
    setIsDrawerOpen(!isDrawerOpen);
  };

  const handlePayslipDownload = (val) => {
    setMakeComplaint(false);
    setIsDrawerOpen(false);
    let res = {
      id: payslip.id,
      date: moment(payslip.date_of_entry).format('YYYY-MM-DD'),
      payslip: `${payslip.employee.first_name} ${payslip.employee.last_name} -  ${moment(payslip.date_of_entry).format("MMMM")}  ${moment(payslip.date_of_entry).format("YYYY")}`
    }
    dispatch(downloadPayslip(res))
  };

  //make complaint modal
  const [makeComplaint, setMakeComplaint] = useState(false);

  const [complaintID, setComplaintID] = useState(null);
  //function to handle closing and opening of modal
  const toggleModal = (id) => {
    setComplaintID(id);
    setMakeComplaint(!makeComplaint);
    setIsDrawerOpen(false);
  };

  //complaint success message
  const handleSubmit = (val) => {
    dispatch(
      handleMakeComplaint({
        id: complaintID,
        complaint_title: val.complaint_title,
        complaint: val.complaint,
      })

    );
    setMakeComplaint(false);
    setIsDrawerOpen(false);
  };

  useEffect(() => {
    dispatch(retrieveMonthlyPayslips(
      {
        page: currentPage,
        page_size: pageSize
      }
    ));
  }, [dispatch, pageSize, currentPage]);

  return (
    <>
      <DrawerComponent
        title="Payslip Info"
        show={isDrawerOpen}
        showDrawer={closePayslip}
      >
        <div className="mt-6">
          <div className="flex justify-between items-center">
            <div>
              <h6 className="text-xs text-secondary1">Name</h6>
              <p className="font-medium text-secondary1 text-sm mt-1">
                {payslip && payslip.employee ? payslip.employee.first_name : "N/A"} {" "} {payslip && payslip.employee ? payslip.employee.last_name : "N/A"}
              </p>
            </div>
          </div>
        </div>
        <div className="mt-6">
          <div className="flex justify-between items-center">
            <div>
              <h6 className="text-xs text-secondary1">Role</h6>
              <p className="font-medium text-secondary1 text-sm mt-1">
                {payslip && payslip.employee ? payslip.employee.position : "N/A"}
              </p>
            </div>
          </div>
        </div>
        <div className="mt-6">
          <div className="flex justify-between items-center">
            <div>
              <h6 className="text-xs text-secondary1">Payslip Period</h6>
              <p className="font-medium text-secondary1 text-sm mt-1">
                {payslip && payslip.date_of_entry
                  ? moment(payslip.date_of_entry).format("MMMM")
                  : "N/A"}
                ,{" "}
                {payslip && payslip.date_of_entry
                  ? moment(payslip.date_of_entry).format("YYYY")
                  : "N/A"}
              </p>
            </div>
          </div>
        </div>
        <div className="mt-6">
          <div className="flex justify-between items-center">
            <div>
              <p className="text-[16px] leading-5 text-primary font-semibold">
                Description
              </p>
            </div>
            <div>
              <p className="text-[16px] leading-5 text-primary font-semibold">
                Amount
              </p>
            </div>
          </div>
        </div>
        <div className="mt-6">
          <div className="flex justify-between items-center">
            <div>
              <p className="text-[14px] leading-[18px] text-secondary1 font-medium">
                Basic Allowance
              </p>
            </div>
            <div>
              <p className="text-[14px] leading-[18px] text-secondary1 font-medium">
                ₦
                {payslip && payslip.gross_salary
                  ? payslip.gross_salary.basic_salary?.toLocaleString()
                  : 0}
              </p>
            </div>
          </div>
        </div>
        <div className="mt-6">
          <div className="flex justify-between items-center">
            <div>
              <p className="text-[14px] leading-[18px] text-secondary1 font-medium">
                Housing Allowance
              </p>
            </div>
            <div>
              <p className="text-[14px] leading-[18px] text-secondary1 font-medium">
                ₦
                {payslip && payslip.gross_salary
                  ? payslip.gross_salary.housing_allowance?.toLocaleString()
                  : 0}
              </p>
            </div>
          </div>
        </div>
        <div className="mt-6">
          <div className="flex justify-between items-center">
            <div>
              <p className="text-[14px] leading-[18px] text-secondary1 font-medium">
                Entertainment Allowance
              </p>
            </div>
            <div>
              <p className="text-[14px] leading-[18px] text-secondary1 font-medium">
                ₦
                {payslip && payslip.gross_salary
                  ? payslip.gross_salary.entertainment_allowance?.toLocaleString()
                  : 0}
              </p>
            </div>
          </div>
        </div>
        <div className="mt-6">
          <div className="flex justify-between items-center">
            <div>
              <p className="text-[14px] leading-[18px] text-secondary1 font-medium">
                Transport Allowance
              </p>
            </div>
            <div>
              <p className="text-[14px] leading-[18px] text-secondary1 font-medium">
                ₦
                {payslip && payslip.gross_salary
                  ? payslip.gross_salary.transport_allowance?.toLocaleString()
                  : 0}
              </p>
            </div>
          </div>
        </div>
        <div className="mt-6">
          <div className="flex justify-between items-center">
            <div>
              <p className="text-[14px] leading-[18px] text-secondary1 font-medium">
                Leave Allowance
              </p>
            </div>
            <div>
              <p className="text-[14px] leading-[18px] text-secondary1 font-medium">
                ₦
                {payslip && payslip.gross_salary
                  ? payslip.gross_salary.leave_allowance?.toLocaleString()
                  : 0}
              </p>
            </div>
          </div>
        </div>
        <div className="mt-6">
          <div className="flex justify-between items-center">
            <div>
              <p className="text-[14px] leading-[18px] text-secondary1 font-medium">
                Utility Allowance
              </p>
            </div>
            <div>
              <p className="text-[14px] leading-[18px] text-secondary1 font-medium">
                ₦
                {payslip && payslip.gross_salary
                  ? payslip.gross_salary.utility_allowance?.toLocaleString()
                  : 0}
              </p>
            </div>
          </div>
        </div>
        <div className="mt-6">
          <div className="flex justify-between items-center">
            <div>
              <p className="text-[14px] leading-[18px] text-secondary1 font-medium">
                Training Allowance
              </p>
            </div>
            <div>
              <p className="text-[14px] leading-[18px] text-secondary1 font-medium">
                ₦
                {payslip && payslip.gross_salary
                  ? payslip.gross_salary.training_allowance?.toLocaleString()
                  : 0}
              </p>
            </div>
          </div>
        </div>
        <div className="mt-6">
          <div className="flex justify-between items-center">
            <div>
              <p className="text-base leading-5 text-primary font-semibold">
                Gross Payable
              </p>
            </div>
            <div>
              <p className="text-base leading-5 text-primary font-semibold">
                ₦
                {payslip && payslip.gross_salary
                  ? payslip.gross_salary.gross_payable?.toLocaleString()
                  : 0}
              </p>
            </div>
          </div>
        </div>
        <div className="mt-6">
          <div className="flex justify-between items-center">
            <div>
              <p className="text-[14px] leading-[18px] text-secondary1 font-medium">
                Taxable Pay
              </p>
            </div>
            <div>
              <p className="text-[14px] leading-[18px] text-secondary1 font-medium">
                ₦
                {payslip && payslip.gross_salary
                  ? payslip.gross_salary.taxable_income?.toLocaleString()
                  : 0}
              </p>
            </div>
          </div>
        </div>
        {/* Deductions */}
        <div className="mt-6">
          <div>
            <p className="text-base leading-5 text-[#B92043] font-semibold">
              Deductions
            </p>
          </div>
        </div>
        <div className="mt-6">
          <div className="flex justify-between items-center">
            <div>
              <p className="text-[14px] leading-[18px] text-secondary1 font-medium">
                PAYE
              </p>
            </div>
            <div>
              <p className="text-[14px] leading-[18px] text-secondary1 font-medium">
                ₦
                {payslip && payslip.deductions
                  ? payslip.deductions.paye?.toLocaleString()
                  : 0}
              </p>
            </div>
          </div>
        </div>
        <div className="mt-6">
          <div className="flex justify-between items-center">
            <div>
              <p className="text-[14px] leading-[18px] text-secondary1 font-medium">
                Employee Pension
              </p>
            </div>
            <div>
              <p className="text-[14px] leading-[18px] text-secondary1 font-medium">
                ₦
                {payslip && payslip.deductions
                  ? payslip.deductions.employee_pension?.toLocaleString()
                  : 0}
              </p>
            </div>
          </div>
        </div>
        <div className="mt-6">
          <div className="flex justify-between items-center">
            <div>
              <p className="text-[14px] leading-[18px] text-secondary1 font-medium">
                HMO
              </p>
            </div>
            <div>
              <p className="text-[14px] leading-[18px] text-secondary1 font-medium">
                ₦
                {payslip && payslip.deductions ? payslip.deductions.hmo?.toLocaleString() : 0}
              </p>
            </div>
          </div>
        </div>
        <>
          {payslip.deductions && payslip.deductions.more_deductions && Object.keys(payslip.deductions.more_deductions).length !== 0 && (
            <div className="mt-6">
              <div className="flex justify-between items-center">
                <div>
                  <p className="text-[14px] leading-[18px] text-secondary1 font-medium">
                    Salary Advance
                  </p>
                </div>
                <div>
                  <p className="text-[14px] leading-[18px] text-secondary1 font-medium">
                    {payslip.deductions.more_deductions && Object.keys(payslip.deductions.more_deductions).length !== 0 ? `₦${payslip.deductions.more_deductions.salary_advance?.toLocaleString()}`
                      : 0}
                  </p>
                </div>
              </div>
            </div>
          )}
        </>
        <>
          {payslip.deductions && payslip.deductions.more_deductions && Object.keys(payslip.deductions.more_deductions).length !== 0 && (
            <div className="mt-6">
              <div className="flex justify-between items-center">
                <div>
                  <p className="text-[14px] leading-[18px] text-secondary1 font-semibold">
                    Loan Repayment
                  </p>
                </div>
                <div>
                  <p className="text-[14px] leading-[18px] text-secondary1 font-medium">
                    {payslip && payslip.deductions && Object.keys(payslip.deductions.more_deductions).length !== 0 ? `₦${payslip.deductions.more_deductions.loan_repayment?.toLocaleString()}`
                      : 0}
                  </p>
                </div>
              </div>
            </div>
          )}
        </>
        <>
          {payslip.deductions && payslip.deductions.more_deductions && Object.keys(payslip.deductions.more_deductions).length !== 0 && (
            <div className="mt-6">
              <div className="flex justify-between items-center">
                <div>
                  <p className="text-[14px] leading-[18px] text-secondary1 font-semibold">
                    Penalty
                  </p>
                </div>
                <div>
                  <p className="text-[14px] leading-[18px] text-secondary1 font-medium">
                    {payslip && payslip.deductions && Object.keys(payslip.deductions.more_deductions).length !== 0 && payslip.deductions.more_deductions.penalty ? `₦${payslip.deductions.more_deductions.penalty?.toLocaleString()}`
                      : 0}
                  </p>
                </div>
              </div>
            </div>
          )}
        </>
        <div className="mt-6">
          <div className="flex justify-between items-center">
            <div>
              <p className="text-base leading-5 text-[#B92043] font-semibold">
                Total Deductions
              </p>
            </div>
            <div>
              <p className="text-base leading-5 text-[#B92043] font-semibold">
                ₦
                {payslip && payslip.deductions
                  ? payslip.deductions.total_deductions?.toLocaleString()
                  : 0}
              </p>
            </div>
          </div>
        </div>
        <div className="mt-6">
          <div className="flex justify-between items-center">
            <div>
              <p className="text-[14px] leading-[18px] text-secondary1 font-medium">
                Overtime
              </p>
            </div>
            <div>
              <p className="text-[14px] leading-[18px] text-secondary1 font-medium">
                {payslip.deductions && payslip.deductions.others && Object.keys(payslip.deductions.others).length !== 0 && payslip.deductions.others.overtime
                  ? `₦${payslip.deductions.others.overtime?.toLocaleString()}`
                  : 0}
              </p>
            </div>
          </div>
        </div>
        <div className="mt-6">
          <div className="flex justify-between items-center">
            <div>
              <p className="text-[14px] leading-[18px] text-secondary1 font-medium">
                Arrears
              </p>
            </div>
            <div>
              <p className="text-[14px] leading-[18px] text-secondary1 font-medium">
                {payslip.deductions && payslip.deductions.others && Object.keys(payslip.deductions.others).length !== 0 && payslip.deductions.others.arrears ?
                  `₦${payslip.deductions.others.arrears?.toLocaleString()}`
                  : 0}
              </p>
            </div>
          </div>
        </div>
        {payslip.deductions && payslip.deductions.others && Object.keys(payslip.deductions.others).length !== 0 && (
          <div className="mt-6">
            <div className="flex justify-between items-center">
              <div>
                <p className="text-[14px] leading-[18px] text-secondary1 font-medium">
                  Bonus
                </p>
              </div>
              <div>
                <p className="text-[14px] leading-[18px] text-secondary1 font-medium">
                  {payslip.deductions && payslip.deductions.others && Object.keys(payslip.deductions.others).length !== 0 ?
                    `₦${payslip.deductions.others.bonus?.toLocaleString()}`
                    : 0}
                </p>
              </div>
            </div>
          </div>
        )}
        <div className="mt-6">
          <div className="flex justify-between items-center">
            <div>
              <p className="text-[16px] leading-5 text-primary font-semibold">
                Net Salary
              </p>
            </div>
            <div>
              <p className="text-[18px] leading-5 text-primary font-semibold">
                ₦{payslip && payslip.net_salary ? payslip.net_salary?.toLocaleString() : 0}
              </p>
            </div>
          </div>
        </div>
        {payslip.approved && <div className="mt-6">
          <div className="flex justify-between items-center">
            <div
              onClick={() =>
                toggleModal(payslip && payslip.id ? payslip.id : "")
              }
            >
              <button className="outline-none bg-white border border-primary text-primary font-medium text-sm py-[10px] px-5 rounded-lg hover:bg-primary hover:border hover:border-primary hover:text-white hover:text-bold transition ease-in-out delay-100">
                Make Complaint
              </button>
            </div>
            <div
              onClick={() =>
                handlePayslipDownload(payslip && payslip.id ? payslip.id : "")
              }
            >
              <button
                className="outline-none bg-primary border border-primary text-white font-medium text-sm py-[10px] px-5 rounded-lg hover:bg-primaryhover hover:border hover:border-primary hover:text-white transition ease-in-out delay-100">
                Download Payslip
              </button>
            </div>
          </div>
        </div>}
      </DrawerComponent>
      <ModalComponent
        title="Submit A Complaint"
        show={makeComplaint}
        showModal={() => setMakeComplaint(false)}
        subtitle={"Complaint Message"}
      >
        <Formik
          onSubmit={(values, { setSubmitting }) =>
            handleSubmit(values, setSubmitting)
          }
          validationSchema={complaintValidator}
          initialValues={{
            complaint_title: '',
            complaint: '',
          }}
        >
          {({ isSubmitting, values, touched, errors, handleChange, handleBlur }) => (
            <Form className="mt-4">
              <div className="">
                <Field
                  type="text"
                  name="complaint_title"
                  placeholder="Complaint Title"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  values={values.complaint_title}
                  className={
                    touched.complaint_title && errors.complaint_title
                      ? 'appearance-none w-full placeholder:text-[#96A397] placeholder:text-sm text-[#344335] text-base focus:border-[#B92043] focus:outline-none rounded-lg border border-[#B92043] bg-white py-3 px-4'
                      : 'outline-0 border border-[#96A397] rounded-[8px] w-full py-[12px] px-[16px] mt-[4px] resize-none'
                  }
                />
                <ErrorMessage name="complaint is required" component="div" className="text-[#B92043] font-medium text-xs pl-[5.65px] pt-[4px]" />
              </div>
              <div className="mt-2">

                <Field
                  as="textarea"
                  name="complaint"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  values={values.complaint}
                  placeholder="Complaint Message"
                  cols="58"
                  rows="5"
                  className={
                    touched.complaint && errors.complaint
                      ? 'appearance-none w-full placeholder:text-[#96A397] placeholder:text-sm text-[#344335] text-base focus:border-[#B92043] focus:outline-none rounded-lg border border-[#B92043] bg-white py-3 px-4'
                      : 'outline-0 border border-[#96A397] rounded-[8px] w-full py-[12px] px-[16px] mt-[4px] resize-none'
                  }
                />
                <ErrorMessage name="complaint" component="div" className="text-[#B92043] font-medium text-xs pl-[5.65px] pt-[4px]" />
              </div>
              <div class="flex justify-end mt-8">
                <button
                  type="submit"
                  disabled={isSubmitting}
                  class="bg-primary text-white outline-none border border-primary font-medium text-sm py-[10px] px-5 rounded-lg hover:bg-primaryhover hover:border hover:border-primary hover:text-white transition ease-in-out delay-100"
                >
                  Submit Complaint
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </ModalComponent>
      <Header text="Payslip"></Header>
      <div className="mt-6 bg-white rounded-[8px]">
        <div className="p-4">
          <h5 className="text-primary font-semibold text-base leading-5">
            Monthly Payslips
          </h5>
          <p className="text-[#7C8D7E] font-medium text-sm">
            This table shows a summary of your monthly payslips.
          </p>
          {/* search */}
          {/* <div className="flex mt-4 gap-4">
            <div className="w-full">
              <SearchComponent
                placeholder="Search"
                searchValue={searchValue}
                handleChange={handleChange}
              />
            </div>
            <div>
              <button className="flex justify-center items-center gap-2 border border-primary rounded-[8px] py-3 px-6 text-primary min-h-[50px] text-sm font-medium leading-5 cursor-pointer">
                <p>Filters</p>
                <img src={filterIcon} alt="filtericon" />
              </button>
            </div>
          </div> */}
        </div>
        {/* Employee Payslip Table */}
        <div className="overflow-x-auto relative">
          <table className="w-full text-left">
            <thead className="text-primary text-sm border-b-[#F7F7F7] border-b-2">
              <tr>
                <th scope="col" className="px-4 py-4 font-semibold w-1/5">
                  Month
                </th>

                <th scope="col" className="px-4 py-4 font-semibold  w-1/5">
                  Year
                </th>
                <th scope="col" className="px-4 py-4 font-semibold  w-1/5">
                  Net Payment
                </th>
                <th scope="col" className="px-4 py-4 font-semibold  w-1/5">
                  Payslip Status
                </th>
                <th scope="col" className="px-4 py-4 font-semibold  w-1/5">
                  Payment Status
                </th>
              </tr>
            </thead>
            {!loading && (
              <tbody>
                {payslips.length > 0 && (
                  payslips.map((item) => (
                    <tr
                      key={item.id}
                      className="bg-white text-sm cursor-pointer hover:bg-[#EDF7EE]"
                      onClick={() => toggleRequest(item.id, item.date_of_entry)}
                    >
                      <th
                        scope="row"
                        className="py-4 px-4 font-normal text-[#5C715E] whitespace-nowrap "
                      >
                        {moment(item.date_of_entry).format("MMMM")}
                      </th>

                      <td className="py-4 px-4 text-[#5C715E]">
                        {" "}
                        {moment(item.date_of_entry).format("YYYY")}
                      </td>
                      <td className="py-4 px-4 text-[#5C715E]">
                        ₦{item.net_salary.amount?.toLocaleString()}
                      </td>
                      <td className="py-4 px-4">
                        <div className="flex">
                          <div
                            className={item.approved ? 'success-badge' : 'pending-badge'}>

                            <p>{item.approved ? 'Approved' : 'Unapproved'}</p>
                          </div>
                        </div>
                      </td>
                      <td className="py-4 px-4 text-[#5C715E]">
                        <div className="flex">
                          <div className={getPaymentStatusBg(item.payment_status)}>
                            <p>{getPaymentStatusText(item.payment_status)}</p>
                          </div>
                        </div>
                      </td>

                    </tr>
                  ))
                )
                }
              </tbody>
            )}
          </table>

          {loading && (
            <LoadingStateComponent />
          )}
        </div>

      </div>
      {!loading && payslips.length < 1 && (
        <div className="bg-transparent h-full pt-70 px-4 mt-32 w-full flex justify-center items-center flex-col gap-4">
          <div className="rounded-full bg-[#DBFFDF] p-3">
            <img src={searchIcon} alt='search' />
          </div>
          <p className="text-base font-medium text-secondary1"> Oops! Seems like you are yet to have a payslip</p>
        </div>
      )}
      {!loading && payslips.length > 0 ? (
        <div className="">
          <PaginationComponent
            page={currentPage}
            onPageChange={(page) => onPageChange({ page })}
            totalItems={total}
            pageSize={pageSize}
            pageSizeShow
            onPageSizeChange={(page) => onPageSizeChange(page)}
          />
        </div>
      ) : (
        ''
      )}

    </>
  );
};

export default EmployeeDBPayslipPage;
