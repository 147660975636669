import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { listEmployeeAllowance } from "../../redux/Employee/employeeAction";
import moment from "moment";
import { useParams } from "react-router-dom";
import EmptyStateComponent from "../../components/Empty State/emptystate";
import PaginationComponent from "../../components/Pagination/Pagination";
import LoadingStateComponent from "../../components/Empty State/loadingstate";

const AllowanceHistoryTable = () => {

  const dispatch = useDispatch();
  const { loading, employeeAllowance, total } = useSelector(
    (state) => state.employee
  );

  //get id from url
  const { id } = useParams()

  const userPk = parseInt(id, 10); // Convert the id to an integer

  //pagination
  const [currentPage, setCurrentPage] = useState(1)
  const [pageSize, setPageSize] = useState(10);

  const onPageSizeChange = (page) => {
    setPageSize(page)
  }

  const onPageChange = (val) => {
    const { page } = val
    setCurrentPage(page)
    dispatch(listEmployeeAllowance({
      page: page,
      page_size: pageSize
    })
    )
  }


  useEffect(() => {
    dispatch(listEmployeeAllowance({ id: userPk, page: 1, page_size: pageSize }));

  }, [dispatch, userPk, pageSize])




  const getPaymentStatusBg = (val) => {
    let result;
    switch (val) {
      case "FAILED":
        result = "error-badge";
        break;
      case "PROCESSING":
        result = "dispatched-badge";
        break;
      case "COMPLETED":
        result = "success-badge";
        break;
      case "PENDING":
        result = "pending-badge";
        break;
      default:
        result = "dispatched-badge";
        break;
    }
    return result;
  };

  const getPaymentStatusText = (val) => {
    let result;
    switch (val) {
      case "PROCESSING":
        result = "Processing";
        break;
      case "FAILED":
        result = "Failed";
        break;
      case "PENDING":
        result = "Pending";
        break;
      case "COMPLETED":
        result = "Completed";
        break;
      default:
        result = "Processing";
        break;
    }
    return result;
  };

  return (
    <>
      <div className="mt-4 bg-white rounded-[8px]">
        <div className="p-4">
          <p className="text-primary font-semibold text-base leading-5">
            Allowance History
          </p>
        </div>

        {/* Tables */}
        <div className="overflow-x-auto relative">
          <table className="w-full text-left">
            <thead className="text-primary text-sm border-b-[#F7F7F7] border-b-2">
              <tr>
                <th scope="col" className="px-4 py-4 font-semibold ">
                  Date
                </th>

                <th scope="col" className="px-4 py-4 font-semibold">
                  Time
                </th>
                <th scope="col" className="px-4 py-4 font-semibold">
                  Allowance Amount
                </th>
                <th scope="col" className="px-4 py-4 font-semibold">
                  Payment Status
                </th>
              </tr>
            </thead>
            {!loading && (
              <tbody>
                {employeeAllowance.length > 0 &&
                  employeeAllowance.map((item) => (
                    <tr
                      key={item.id}
                      className="bg-white text-sm cursor-pointer hover:bg-[#EDF7EE]"
                    >
                      <th
                        scope="row"
                        className="py-4 px-4 font-normal text-[#5C715E] whitespace-nowrap "
                      >
                        {moment(item.date_of_entry).format("MMMM Do, YYYY")}
                      </th>

                      <td className="py-4 px-4 text-[#5C715E]">
                        {moment(item.date_of_entry).format("h:mm a")}
                      </td>
                      <td className="py-4 px-4 text-[#5C715E]">
                        {item.allowance_payable ? `₦${item.net_allowance?.toLocaleString()}` : "N/A"}
                      </td>
                      <td className="py-4 px-4">
                        <div className="flex">
                          <div className={getPaymentStatusBg(item.processed)}>
                            <p>{getPaymentStatusText(item.processed)}</p>
                          </div>
                        </div>
                      </td>
                    </tr>
                  ))}
              </tbody>
            )}
          </table>

          {loading && (
            <LoadingStateComponent />
          )}
        </div>
      </div>
      {!loading && employeeAllowance.length < 1 && (
        <EmptyStateComponent
          textContent="Oops! No allowance history available."></EmptyStateComponent>
      )}
      {!loading && employeeAllowance.length > 0 ? (
        <div className="">
          <PaginationComponent
            page={currentPage}
            onPageChange={(page) => onPageChange({ page })}
            totalItems={total}
            pageSize={pageSize}
            pageSizeShow
            onPageSizeChange={(page) => onPageSizeChange(page)}
          />
        </div>
      ) : (
        ''
      )}
    </>
  );
};

export default AllowanceHistoryTable;
