import React, { useState, useMemo, useCallback, useEffect } from 'react'
import SearchComponent from '../../components/search'
import moment from 'moment'
import { debounce } from 'lodash'
import ModalComponent from '../../components/Modals/modal'
import { listApprovedPayslips, makePayslipPayment, sendBulkEmail } from '../../redux/Payroll/payrollAction'
import LoadingStateComponent from '../../components/Empty State/loadingstate'
import EmptyStateComponent from '../../components/Empty State/emptystate'
import { useSelector, useDispatch } from 'react-redux'
import { handleCheckedApprovedPayslips, handlePayAllApprovedPayslips, clearApprovedPayslips } from '../../redux/Payroll/payroll'
import PaginationComponent from '../../components/Pagination/Pagination'
import cogoToast from 'cogo-toast'

const ApprovedPayslipsTable = () => {
    const [typeID, setTypeID] = useState(1)
    //keep track of checked state of checkedboxees
    const [selectAllChecked, setSelectAllChecked] = useState(false);

    //bulk send payslip
    const [payslipDate, setPayslipDate] = useState(new Date());
    const [insertDate, setInsertDate] = useState(false);

    const toggleInsertDate = () => {
        setInsertDate(!insertDate);
    };

    //pagination
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);


    const onPageSizeChange = (pageSize) => {
        setPageSize(pageSize);
        dispatch(listApprovedPayslips({ type: callTypes[typeID - 1].tag, page: currentPage, page_size: pageSize }));
    };


    const callTypes = [
        {
            id: 1,
            title: 'All',
            tag: 'all'
        },
        {
            id: 2,
            title: 'Pending',
            tag: 'pending'
        },
        {
            id: 3,
            title: 'Processing',
            tag: 'processing'
        },
        {
            id: 4,
            title: 'Completed',
            tag: 'completed'
        },
        {
            id: 5,
            title: 'Failed',
            tag: 'failed'
        }

    ]

    const onPageChange = (val) => {
        const { page } = val
        setCurrentPage(page)
        switch (typeID) {
            case 1:
                dispatch(
                    listApprovedPayslips({
                        type: callTypes.tag,
                        page: page,
                        page_size: pageSize
                    }),
                )
                break
            case 2:
                dispatch(
                    listApprovedPayslips({
                        type: 'pending',
                        page: page,
                        page_size: pageSize
                    })
                )
                break
            case 3:
                dispatch(
                    listApprovedPayslips({
                        type: 'processing',
                        page: page,
                        page_size: pageSize
                    })
                )
                break
            case 4:
                dispatch(
                    listApprovedPayslips({
                        type: 'completed',
                        page: page,
                        page_size: pageSize
                    })
                )
                break
            case 5:
                dispatch(
                    listApprovedPayslips({
                        type: 'failed',
                        page: page,
                        page_size: pageSize
                    })
                )
                break
            default:
                break
        }
    }

    const dispatch = useDispatch();
    const { loading, approvedPayslips, checkedApprovedPayslips, total } = useSelector((state) => state.payroll)

    useEffect(() => {
        dispatch(listApprovedPayslips({ type: 'all', page: 1, page_size: pageSize }))
    }, [dispatch, pageSize])



    const toggleType = (id, tag) => {
        setTypeID(id)
        switch (id) {
            case 1:
                dispatch(listApprovedPayslips({ type: tag, page: 1, page_size: pageSize }))
                break;
            case 2:
                dispatch(listApprovedPayslips({ type: tag, page: 1, page_size: pageSize }))
                break;
            case 3:
                dispatch(listApprovedPayslips({ type: tag, page: 1, page_size: pageSize }))
                break;
            case 4:
                dispatch(listApprovedPayslips({ type: tag, page: 1, page_size: pageSize }))
                break;
            case 5:
                dispatch(listApprovedPayslips({ type: tag, page: 1, page_size: pageSize }))
                break;
            default:
                dispatch(listApprovedPayslips({ type: 'all', page: 1, page_size: pageSize }))
                break;
        }
    }

    const getPaymentStatusBg = (val) => {
        let result;
        switch (val) {
            case 'FAILED':
                result = 'error-badge'
                break
            case 'PROCESSING':
                result = 'dispatched-badge'
                break
            case 'COMPLETED':
                result = 'success-badge'
                break
            case 'PENDING':
                result = 'pending-badge'
                break
            case 'DONE':
                result = 'done-badge'
                break
            case 'PAY':
                result = 'pay-badge'
                break
            default:
                result = 'dispatched-badge'
                break
        }
        return result
    }

    const getPaymentStatusText = (val) => {
        let result
        switch (val) {
            case 'PROCESSING':
                result = 'Processing'
                break
            case 'FAILED':
                result = 'Failed'
                break
            case 'PENDING':
                result = 'Pending'
                break
            case 'COMPLETED':
                result = 'Completed'
                break
            default:
                result = 'Processing'
                break
        }
        return result
    }

    const getApprovedPayslipActionText = (val) => {
        let result
        switch (val) {
            case 'PAY':
                result = 'Pay'
                break
            case 'DONE':
                result = 'Paid'
                break
            default:
                result = 'Pay'
                break
        }
        return result
    }

    // handle search
    const [searchValue, setSearchValue] = useState('')
    const handleSearch = useMemo(
        () =>
            debounce((val) => {
                //  api to dispatch and make the actions for search
                //console.log(val); 
            }, 750),
        [],
    )

    const handleChange = useCallback(
        (e) => {
            let val = e.target.value
            setSearchValue(val)
            handleSearch(val)
        },
        [handleSearch],
    )
    const [isProcessPayment, setIsProcessPayment] = useState(
        false
    )

    const toggleModal = () => {
        // if (checkedApprovedPayslips.length > 0) {
        //     setIsProcessPayment(!isProcessPayment);
        //   }
        // setIsProcessPayment(!isProcessPayment)
        if (checkedApprovedPayslips.length === 0) {
            // Bulk payment scenario
            setIsProcessPayment(prevState => !prevState);
            // Make the bulk payment API call here
            // console.log("making payments");
            dispatch(handlePayAllApprovedPayslips());
        } else {
            // Single or multiple payment scenario
            setIsProcessPayment(prevState => !prevState);
            // Make the payment API call for the selected payslips here

        }

    }


    const handleSendBulkPayslip = () => {
        // Logic for other action
        dispatch(sendBulkEmail({ date_of_entry: moment(payslipDate).format("YYYY-MM-DD") }))
    };



    const textContent = (id) => {
        let res;
        switch (id) {
            case 1:
                res = 'Oops! No payslip available'
                break;
            case 2:
                res = 'Oops! No pending payslip(s) available.'
                break;
            case 3:
                res = 'Oops! No processing payslip(s) available.'
                break;
            case 4:
                res = 'Oops! No completed payslip(s) available.'
                break;
            case 5:
                res = 'Oops! No failed payslip(s) available.'
                break;
            default:
        }
        return res;
    }

    const handleCheckedBox = useCallback(
        (employeeID, payslipID, dateOfEntry) => {
            dispatch(
                handleCheckedApprovedPayslips({
                    id: employeeID,
                    payslip_id: payslipID,
                    date_of_entry: moment(dateOfEntry).format("YYYY-MM-DD"),
                })
            );
            //console.log(checkedApprovedPayslips);
        },
        [dispatch]
    );

    //toggle the  checked state of all checkboxes and update the checkedApprovedPayslips array accordingly
    const handleSelectAllCheckbox = () => {
        setSelectAllChecked(prevState => !prevState);

        if (!selectAllChecked) {
            const allPayslips = approvedPayslips.map(payslip => ({
                id: payslip.employee.id,
                payslip_id: payslip.id,
                date_of_entry: moment(payslip.date_of_entry).format('YYYY-MM-DD')
            }));
            //console.log(allPayslips)
            dispatch(handlePayAllApprovedPayslips(allPayslips));
        } else {
            dispatch(clearApprovedPayslips());
        }
    }

    //cancel checked approved payslips
    const cancelCheckedApprovedPayslips = () => {
        dispatch(clearApprovedPayslips())
    }

    //function to handle modal and approved checked box
    const handleModalClick = (employee, id, entryDate) => {
        if (checkedApprovedPayslips.length > 0) {
            cogoToast.error("Kindly use the 'Pay All' button to handle multiple payments simultaneously!")
        } else {
            dispatch(handleCheckedApprovedPayslips({
                id: employee,
                payslip_id: id,
                date_of_entry: moment(entryDate).format("YYYY-MM-DD")
            }));
            toggleModal()
        }
    }

    useEffect(() => {
        // Check if all items are checked, and update selectAllChecked state accordingly
        setSelectAllChecked(checkedApprovedPayslips.length === approvedPayslips.length);


        if (checkedApprovedPayslips.length === 0 && approvedPayslips.length === 0) {
            setSelectAllChecked(false)
        }
    }, [checkedApprovedPayslips, approvedPayslips]);


    return (
        <>
            < ModalComponent
                title="Process Payment"
                show={isProcessPayment}
                showModal={() => {
                    setIsProcessPayment(false);
                    cancelCheckedApprovedPayslips()
                }}
                subtitle="Are you sure you sure you want to pay?"
            >
                <div className="pt-[32px] flex justify-end gap-[40px] items-center mb-[12px]">
                    <button
                        onClick={() => {
                            setIsProcessPayment(false);
                            cancelCheckedApprovedPayslips()
                        }}
                        className="text-sm text-medium leading-5 text-[#5C715E]">No</button>
                    <button
                        className="outline-0 bg-primary rounded-[8px] w-[62px] h-[40px] text-sm text-medium leading-5 text-white"
                        disabled={loading}
                        onClick={() => {
                            // console.log("Loading payment...");
                            dispatch(makePayslipPayment());
                            setIsProcessPayment(false);
                        }}>
                        Yes
                    </button>
                </div>
            </ModalComponent>
            <ModalComponent
                title={"Send Bulk Payslips"}
                show={insertDate}
                showModal={() => {
                    setInsertDate(false);
                }}
            >
                <div>
                    <div className="flex items-center justify-center gap-6 mt-4">
                        <div>
                            <p className="text-secondary text-sm font-normal">
                                Enter the date for sending bulk payslips"
                            </p>
                            <input
                                type="date"
                                placeholder="dd/mm/yyyy"
                                name="payslipDate"
                                value={payslipDate}
                                onChange={(e) => setPayslipDate(e.target.value)}
                                className="text-[#96A397] p-3 outline-0 h-[44px] bg-white rounded-[8px] border border-[#96A397] mt-[4px] w-full font-medium text-sm"
                            />
                        </div>
                    </div>
                    <div className="mt-4 flex justify-center align-center items-center">
                        <button
                            onClick={() => handleSendBulkPayslip()}
                            className="outline-none rounded-lg disabled:text-[#ABB6AC] disabled:bg-[#ABB6AC] bg-primary text-white py-3 px-5 text-sm font-medium border border-primary hover:bg-[#24752B] hover:bg-opacity-[0.9]"
                        >
                            Bulk Send Payslips Via Email
                        </button>
                    </div>
                </div>
            </ModalComponent>
            <div className="mt-4 bg-white rounded-[8px]">
                <div className="p-4">
                    {/* search */}
                    <div className="flex gap-4 mt-4">
                        <div className="w-full">
                            <SearchComponent
                                placeholder="Search..."
                                searchValue={searchValue}
                                handleChange={handleChange}
                            />
                        </div>

                        <div className="flex flex-row gap-4">
    
                            {typeID === 2 &&
                                 <button
                                 type="submit"
                                 onClick={() => { toggleInsertDate(); }}
                                 disabled="" //hover color is #24752B
                                 className="bg-transparent disabled:bg-[#ABB6AC] border-[1px] rounded-lg w-full py-2.5 px-5 text-primary border-primary font-medium hover:bg-[#24752B] hover:text-bold hover:text-white transition-all text-sm"
                               >
                                 Send All Payslips
                               </button>
                            }

                            {typeID !== 1 && typeID !== 3 && typeID !== 4 &&
                                <button
                                    className="bg-primary flex justify-center item-center py-3 px-3 text-sm rounded-lg min-w-[80px] min-h-[50px] text-white"
                                    onClick={toggleModal}>
                                    <p>Pay All</p>
                                </button>}
                        </div>

                    </div>

                    <ul className="flex mt-5 gap-5 items-center">
                        {callTypes.map((item) => (
                            <li
                                key={item.id}
                                onClick={() => toggleType(item.id, item.tag)}
                                className={
                                    typeID === item.id
                                        ? 'flex gap-1 items-center border-b-2 border-primary pb-1 cursor-pointer'
                                        : 'flex gap-2 items-center pb-1 cursor-pointer'
                                }
                            >
                                <p
                                    className={
                                        typeID === item.id
                                            ? 'text-primary text-sm font-semibold'
                                            : 'text-[#96A397] text-sm font-semibold'
                                    }
                                >
                                    {item.title}
                                </p>
                            </li>
                        ))}
                    </ul>
                </div>

                {/* tables */}
                <div className="overflow-x-auto relative">
                    <table className="w-full text-left">
                        <thead className="text-primary text-sm border-b-[#F7F7F7] border-b-2">
                            <tr>
                                {typeID !== 1 && typeID !== 3 && typeID !== 4 && <th scope="col" className="px-4 py-4 font-semibold ">
                                    <input
                                        type="checkbox"
                                        checked={selectAllChecked}
                                        onChange={() => handleSelectAllCheckbox()} />
                                </th>}
                                <th scope="col" className="px-4 py-4 font-semibold ">
                                    Employee ID
                                </th>
                                <th scope="col" className="px-4 py-4 font-semibold">
                                    First Name
                                </th>
                                <th scope="col" className="px-4 py-4 font-semibold">
                                    Last Name
                                </th>
                                <th scope="col" className="px-4 py-4 font-semibold">
                                    Income Type
                                </th>
                                <th scope="col" className="px-4 py-4 font-semibold">
                                    Amount
                                </th>
                                <th scope="col" className="px-4 py-4 font-semibold">
                                    Payment Status
                                </th>
                                {typeID !== 1 && typeID !== 3 && typeID !== 4 && <th scope="col" className="px-4 py-4 font-semibold">
                                    Action
                                </th>}
                            </tr>
                        </thead>
                        {!loading && (
                            <tbody>
                                {approvedPayslips.length > 0 && approvedPayslips.map((item) => (
                                    <tr
                                        key={item.id}
                                        className="bg-white text-sm cursor-pointer hover:bg-[#EDF7EE]"
                                    >
                                        {typeID !== 1 && typeID !== 3 && typeID !== 4 && <th
                                            scope="row"
                                            className="py-4 px-4 font-normal text-[#5C715E]"
                                        >
                                            <input
                                                type="checkbox"
                                                name="approved_payslip_child"
                                                checked={checkedApprovedPayslips.some(val => val.payslip_id === item.id)}
                                                onChange={() => handleCheckedBox(item.employee.id, item.id, item.date_of_entry,)}

                                            />
                                        </th>}

                                        <td className="py-4 px-4 text-[#5C715E]">
                                            {item.employee ? item.employee.employee_id : "N/A"}
                                        </td>
                                        <td className="py-4 px-4 text-[#5C715E]">
                                            {item.employee ? item.employee.first_name : "N/A"}
                                        </td>
                                        <td className="py-4 px-4 text-[#5C715E]">
                                            {item.employee ? item.employee.last_name : "N/A"}
                                        </td>
                                        <td className="py-4 px-4 text-[#5C715E]">
                                            {item.net_salary ? item.net_salary.payment_type : "N/A"}
                                        </td>
                                        <td className="py-4 px-4 text-[#5C715E]">
                                            {item.net_salary ? item.net_salary.amount?.toLocaleString() : "N/A"}
                                        </td>
                                        <td className="py-4 px-4 text-[#5C715E]">
                                            <div className="flex">
                                                <div className={getPaymentStatusBg(item.payment_status)}>
                                                    <p>{getPaymentStatusText(item.payment_status)}</p>
                                                </div>
                                            </div>
                                        </td>
                                        {typeID !== 1 && typeID !== 3 && typeID !== 4 && <td className="py-4 px-4">
                                            <div className="flex">
                                                <div
                                                    className={`${checkedApprovedPayslips.length > 1 ? "pointer-events-none" : ""
                                                        } ${getPaymentStatusBg(
                                                            item.payment_status === "COMPLETED" ? "DONE" : "PAY"
                                                        )}`}
                                                    onClick={() => handleModalClick(item.employee.id, item.id, item.date_of_entry)}
                                                >
                                                    <p>{getApprovedPayslipActionText(item.payment_status === "COMPLETED" ? "DONE" : "PAY")}</p>
                                                </div>
                                            </div>
                                        </td>}
                                    </tr>
                                ))
                                }
                            </tbody>
                        )}
                    </table>

                    {loading &&
                        <LoadingStateComponent />
                    }
                </div>
            </div>
            {!loading && approvedPayslips.length < 1 &&
                <EmptyStateComponent textContent={textContent(typeID)} />
            }
            {!loading && approvedPayslips.length > 0 ? (
                <div className="">
                    <PaginationComponent
                        page={currentPage}
                        onPageChange={(page) => onPageChange({ page })}
                        pageSize={pageSize}
                        totalItems={total}
                        onPageSizeChange={(page) => onPageSizeChange(page)}
                        pageSizeShow
                    />
                </div>
            ) : (
                ''
            )}
        </>
    );
}

export default ApprovedPayslipsTable;