import React, { useState, useEffect, useCallback } from "react";
import Header from "../../../components/header";
import { useSelector, useDispatch } from "react-redux";
import { getBanks, getRoles, verifyAccountNumber, getDepartments } from "../../../redux/Auth/authAction";
import { editEmployeeProfile } from "../../../redux/Employee/employeeAction";
// import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useParams } from "react-router-dom";
import spinningLoader from "../../../assets/loader.svg"
import Select from "react-select"
import GoBack from "../../../components/goBack";

const EditEmployeeProfilePage = () => {
  const dispatch = useDispatch();

  //get id from url
  const { id } = useParams()
  //retrieve the employee details 
  const { employees } = useSelector(state => state.employee)
  const { banks, roles, verifyLoader, account_name, departments } = useSelector(state => state.auth)

  //to get an employee
  let employee = employees.find((val) => val.id === parseInt(id))

  // console.log(roles)
  //input fields
  const [firstName, setFirstName] = useState(employee.first_name);
  const [lastName, setLastName] = useState(employee.last_name);
  const [employeeID, setEmployeeID] = useState(employee.paytimeuserprofile.employee_id !== null ? employee.paytimeuserprofile.employee_id : '');
  const [role, setRole] = useState(employee ? employee.paytimeuserprofile.role : "");
  const [roleSlug, setRoleSlug] = useState("")
  const [pensionID, setPensionID] = useState(employee.paytimeuserprofile && employee.paytimeuserprofile.pension_id ? employee.paytimeuserprofile.pension_id : "")
  const [email, setEmail] = useState(employee.email);
  const [bank, setBank] = useState(employee.paytimeuserprofile.bank ? employee.paytimeuserprofile.bank : "");
  const [slug] = useState(employee.paytimeuserprofile.bank_slug ? employee.paytimeuserprofile.bank_slug : "");
  const [department, setDepartment] = useState(employee.paytimeuserprofile.department ? employee.paytimeuserprofile.department : "")
  const [accountNumber, setAccountNumber] = useState(employee.paytimeuserprofile && employee.paytimeuserprofile.account_number ? employee.paytimeuserprofile.account_number : "");
  const [accountName, setAccountName] = useState(employee.paytimeuserprofile && employee.paytimeuserprofile.account_name ? employee.paytimeuserprofile.account_name : "");
  const [defaultRole, setDefaultRole] = useState([])
  const [defaultBank, setDefaultBank] = useState([])
  const [defaultDepartment, setDefaultDepartment] = useState([])

  //working with select for banks
  const [bankSlug, setBankSlug] = useState('')
  const [bankCode, setBankCode] = useState('')

  const handleBank = (val) => {
    let slug = '';
    let bankName = '';
    let code = '';
    if (typeof val === 'object' && val !== null) {
      //  bankArray.push(val.id);
      const { bank_slug, bank_name, bank_code } = val;
      slug = `${bank_slug}`;
      bankName = `${bank_name}`
      code = `${bank_code}`
    }
    setBank(bankName)
    setBankSlug(slug)
    setBankCode(code)
  };

  //working with select for roles
  const handleRole = (val) => {
    //console.log(val);
    const { role_slug, role_name } = val;
    setRoleSlug(role_slug)
    setRole(role_name)
    //console.log(role, "role slug new is ", role_slug, "new role name is", role_name)
  };

  //working select for departments
  const handleDepartment = (val) => {
    let dept;
    if (typeof val === 'object' && val !== null) {
      const { department_name } = val;
      dept = `${department_name}`;
    }
    setDepartment(dept)
  };

  //filter role
  const handleFilterRole = useCallback((val) => {

    let filteredRole = roles.filter(tag => val.includes(tag.role_name))
    setDefaultRole(filteredRole)
    //console.log(filteredRole)
  }, [roles])

  useEffect(() => {
    handleFilterRole(role);
  }, [handleFilterRole, role])

  //filter bank
  const handleFilterBank = useCallback((val) => {
    let filteredBank = banks.filter(tag => val.includes(tag.bank_name))
    setDefaultBank(filteredBank)
  }, [banks])

  useEffect(() => {
    handleFilterBank(bank);
  }, [handleFilterBank, bank])

  //filter department
  const handleFilterDepartment = useCallback((val) => {
    let filteredDept = departments.filter(tag => val.includes(tag.department_name))
    setDefaultDepartment(filteredDept)
    //console.log(filteredDept)
  }, [departments])

  useEffect(() => {
    handleFilterDepartment(department)
  }, [handleFilterDepartment, department])

  //calling of banks, roles and department api
  useEffect(() => {
    dispatch(getBanks())
  }, [dispatch])

  useEffect(() => {
    dispatch(getRoles())
  }, [dispatch])

  useEffect(() => {
    dispatch(getDepartments())
  }, [dispatch])

  //verify account number 
  const handleVerifyAccountNumber = (val) => {
    const regex = /^\d{10}$/

    const isValidAccountNumber = regex.test(val);

    if (isValidAccountNumber) {
      dispatch(verifyAccountNumber({
        account_number: val,
        bank: `${bankSlug}|${bankCode}`
      }),)

      setAccountName(account_name)
    }
  }

  const handleSaveChanges = () => {
    let data;
    setAccountName(account_name)
    data = {
      pension_id: pensionID,
      account_number: accountNumber,
      role: roleSlug,
      department: department,
      account_name: account_name ? account_name : accountName,
      bank: bank,
      bank_slug: bankSlug ? bankSlug : slug
    }
    dispatch(editEmployeeProfile({ data, id }))
  }



  return (
    <>
      <GoBack />
      <Header text="Edit Employee Profile"></Header>
      <div className="w-full h-full px-4 mt-4 bg-white rounded-[8px] py-8">
        <h5 className="text-[#ABB6AC] font-semibold text-sm leading-[18px] pt-6 pb-4">
          Personal Details
        </h5>
        <div className="flex gap-6">
          <div className="w-1/3">
            <div>
              <p className="font-[400] text-[14px] leading-[18px] text-secondary">
                Employee ID
              </p>
              <input
                type="text"
                value={employeeID}
                disabled={true}
                onChange={(event) => setEmployeeID(event.target.value)}
                className="outline-0 h-[44px] bg-[#F7F7F7]  rounded-[8px] border border-[#96A397] mt-[4px] py-[12px] px-[14px] w-full font-medium text-sm text-secondary mb-[24px]"
                placeholder="Employee ID"
              />
            </div>
            <div>
              <h6 className="font-normal text-sm leading-[18px] text-secondary mb-1">
                Role
              </h6>
              <Select
                onChange={handleRole}
                options={roles}
                value={defaultRole}
                name="role"
                placeholder="Select Role"
                className="select_style"
                classNamePrefix="select_control"
                getOptionLabel={options => options.role_name}
                getOptionValue={options => options.id}
              ></Select>
            </div>
            <div className="mt-6 mb-1">
              <h6 className="font-normal text-sm leading-[18px] text-secondary ">
                Bank
              </h6>
              <Select
                onChange={handleBank}
                options={banks}
                value={defaultBank}
                name="bank"
                placeholder="Select Bank"
                className="select_style"
                classNamePrefix="select_control"
                getOptionLabel={options => options.bank_name}
                getOptionValue={options => options.id}
              ></Select>

            </div>
            <div className="mt-6 mb-1">
              <h6 className="font-normal text-sm leading-[18px] text-secondary ">
                Department
              </h6>
              <Select
                onChange={handleDepartment}
                options={departments}
                value={defaultDepartment}
                name="bank"
                placeholder="Select Department"
                className="select_style"
                classNamePrefix="select_control"
                getOptionLabel={options => options.department_name}
                getOptionValue={options => options.id}
              ></Select>

            </div>
          </div>
          <div className="w-1/3">
            <div>
              <h6 className="font-normal text-sm leading-[18px] text-secondary">
                First Name
              </h6>
              <input
                type="text"
                value={firstName}
                onChange={(event) => setFirstName(event.target.value)}
                // onChange={(e) => {
                //     setValue(e.target.value)
                // }}
                className="outline-0 h-[44px] bg-white rounded-[8px] border border-[#96A397] mt-[4px] py-[12px] px-[14px] w-full font-medium text-sm text-secondary mb-[24px]"
                placeholder="First Name"
              />
            </div>
            <div>
              <h6 className="font-normal text-sm leading-[18px] text-secondary">
                Pension ID
              </h6>
              <input
                type="text"
                value={pensionID}
                onChange={(event) => setPensionID(event.target.value)}
                className="outline-0 h-[44px] bg-white rounded-[8px] border border-[#96A397] mt-[4px] py-[12px] px-[14px] w-full font-medium text-sm text-secondary mb-[24px]"
                placeholder="Pension ID"
                defaultValue="0123456789"
              />
            </div>
            <div>
              <h6 className="font-normal text-sm leading-[18px] text-secondary">
                Account No.
              </h6>
              <div className="relative">
                <input
                  type="text"
                  value={accountNumber}
                  onChange={(event) => {
                    setAccountNumber(event.target.value);
                    handleVerifyAccountNumber(event.currentTarget.value)
                  }}
                  className="outline-0 h-[44px] bg-white rounded-[8px] border border-[#96A397] mt-[4px] py-[12px] px-[14px] w-full font-medium text-sm text-secondary mb-[24px]"
                  placeholder="Account Number"
                  defaultValue="0123456789"
                />
                {verifyLoader &&
                  <div className="absolute top-1/2 transfrom -translate-y-1/2 right-4 cursor-pointer">
                    <img
                      src={spinningLoader}
                      alt="loading"
                      className="animate-spin"
                    />
                  </div>
                }
              </div>
            </div>
          </div>
          <div className="w-1/3">
            <div>
              <h6 className="font-normal text-sm leading-[18px] text-secondary">
                Last Name
              </h6>
              <input
                type="text"
                value={lastName}
                onChange={(event) => setLastName(event.target.value)}
                className="outline-0 h-[44px] bg-white rounded-[8px] border border-[#96A397] mt-[4px] py-[12px] px-[14px] w-full font-medium text-sm text-secondary mb-[24px]"
                placeholder="Last Name"
                defaultValue="Ajisafe"
              />
            </div>
            <div>
              <h6 className="font-normal text-sm leading-[18px] text-secondary">
                Email Address
              </h6>
              <input
                type="text"
                value={email}
                disabled={true}
                onChange={(event) => setEmail(event.target.value)}
                className="outline-0 h-[44px] bg-[#F7F7F7]  rounded-[8px] border border-[#96A397] mt-[4px] py-[12px] px-[14px] w-full font-medium text-sm text-secondary mb-[24px]"
                placeholder="Email Address"
                defaultValue="jemimaosunde@crop2cash.com.ng"
              //onChange={(event) => {setEmail= event.target.value}}
              />
            </div>
            <div>
              <p className="font-[400] text-[14px] leading-[18px] text-secondary">
                Account Name
              </p>
              <input
                type="text"
                value={account_name ? account_name : accountName}
                disabled={true}
                //onChange={(e) => setAccountName(account_name)}
                className="outline-0 h-[44px] bg-[#F7F7F7] rounded-[8px] border border-[#96A397] mt-[4px] py-[12px] px-[14px] w-full font-medium text-sm text-secondary mb-[24px]"
                placeholder="Account Name"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="flex items-center justify-end gap-6 mt-6">
        {/* <div>
          <button className="outline-none bg-white border border-primary text-primary font-medium text-sm leading-5 py-[10px] px-[20px] rounded-[8px] hover:bg-primary hover:border hover:border-primary hover:text-white transition ease-in-out delay-100">
            Cancel
          </button>
        </div> */}
        <div>
          <button
            onClick={handleSaveChanges}
            className="outline-none bg-primary border border-primary text-white font-medium text-sm leading-5 py-[10px] px-[20px] rounded-[8px] hover:bg-primaryhover hover:border hover:border-primary hover:text-white transition ease-in-out delay-100">
            Save
          </button>
        </div>
      </div>
    </>
  );
};

export default EditEmployeeProfilePage;
