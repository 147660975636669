import { combineReducers} from "@reduxjs/toolkit";

import authReducer from './Auth/auth'
import payslipReducer from './Payslip/payslip'
import overviewReducer from './Overview/overview'
import payrollReducer from './Payroll/payroll'
import employeeReducer from './Employee/employee'
import pensionReducer from './Pension/pension'
import payslipCalculatorReducer from './Calculator/calculator'
import complaintsReducer from './Complaints/complaints'
import recordReducer from './Records/records'
import allowanceReducer from './Allowance/allowance'
import advanceReducer from "./SalaryAdvance/advance";


const appReducer = combineReducers({
        auth: authReducer,
        payslip: payslipReducer,
        overview: overviewReducer,
        payroll: payrollReducer,
        employee: employeeReducer,
        pension: pensionReducer,
        payslipCalculator: payslipCalculatorReducer,
        complaints: complaintsReducer,
        record: recordReducer,
        allowance: allowanceReducer,
        advance: advanceReducer
})

const rootReducer = (state, action) => {
    if (action.type === 'auth/Logout') {
      state = undefined;
    }
    return appReducer(state, action);
  };

export default rootReducer