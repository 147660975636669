import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux";
import Header from '../../components/header';
import moment from "moment";
import LoadingStateComponent from "../../components/Empty State/loadingstate";
import EmptyStateComponent from "../../components/Empty State/emptystate"
import { allowanceTable } from "../../redux/Allowance/allowanceAction";
import PaginationComponent from "../../components/Pagination/Pagination"

const AllowancePage = () => {

    const dispatch = useDispatch();

    const { loading, allowance, total } = useSelector(
        (state) => state.allowance
    );
    //pagination
    const [currentPage, setCurrentPage] = useState(1)
    const [pageSize, setPageSize] = useState(10);

    const onPageSizeChange = (page) => {
        setPageSize(page)
    }

    const onPageChange = (val) => {
        const { page } = val
        setCurrentPage(page)
        dispatch(allowanceTable({
            page: page,
            page_size: pageSize
        })
        )
    }

    useEffect(() => {
        dispatch(allowanceTable({
            page: currentPage,
            page_size: pageSize
        }));
    }, [dispatch, pageSize, currentPage]);

    const getPaymentStatusBg = (val) => {
        let result;
        switch (val) {
            case 'FAILED':
                result = 'error-badge'
                break
            case 'PROCESSING':
                result = 'dispatched-badge'
                break
            case 'COMPLETED':
                result = 'success-badge'
                break
            case 'PENDING':
                result = 'pending-badge'
                break
            case 'DONE':
                result = 'done-badge'
                break
            case 'PAY':
                result = 'pay-badge'
                break
            default:
                result = 'dispatched-badge'
                break
        }
        return result
    }

    const getPaymentStatusText = (val) => {
        let result
        switch (val) {
            case 'PROCESSING':
                result = 'Processing'
                break
            case 'FAILED':
                result = 'Failed'
                break
            case 'PENDING':
                result = 'Pending'
                break
            case 'COMPLETED':
                result = 'Completed'
                break
            default:
                result = 'Processing'
                break
        }
        return result
    }

    return (
        <>
            <Header
                text="Allowance"
            >
            </Header>
            <div className="mt-4 bg-white rounded-[8px]">
                <div className="p-4">
                    <h5 className="text-primary font-semibold text-base leading-5">
                        Monthly Allowance
                    </h5>
                    <p className="text-[#7C8D7E] font-medium text-sm">
                        This table shows a summary of your mothly allowance
                    </p>
                </div>

                {/* Allowance Table */}
                <div className="overflow-x-auto relative">
                    <table className="w-full text-left">
                        <thead className="text-primary text-sm border-b-[#F7F7F7] border-b-2">
                            <tr>
                                <th scope="col" className="px-4 py-4 font-semibold w-1/4">
                                    Month
                                </th>

                                <th scope="col" className="px-4 py-4 font-semibold w-1/4">
                                    Year
                                </th>
                                <th scope="col" className="px-4 py-4 font-semibold w-1/4">
                                    Allowance
                                </th>
                                <th scope="col" className="px-4 py-4 font-semibold w-1/4">
                                    Payment Status
                                </th>
                            </tr>
                        </thead>
                        {!loading && (
                            <tbody>
                                {allowance.length > 0 && allowance.map((item) => (
                                    <tr
                                        key={item.id}
                                        className="bg-white text-sm hover:bg-[#EDF7EE]"
                                    >
                                        <th
                                            scope="row"
                                            className="py-4 px-4 font-normal text-[#5C715E] whitespace-nowrap w-1/4"
                                        >
                                            {moment(item.date_of_entry).format("MMMM")}
                                        </th>

                                        <td className="py-4 px-4 text-[#5C715E] w-1/4">

                                            {moment(item.date_of_entry).format("YYYY")}
                                        </td>
                                        <td className="py-4 px-4 text-[#5C715E] w-1/4">
                                            ₦{item.net_allowance?.toLocaleString()}
                                        </td>
                                        <td className="py-4 px-4 text-[#5C715E] w-1/4">
                                            <div className="flex">
                                                <div className={getPaymentStatusBg(item.processed)}>
                                                    <p>{getPaymentStatusText(item.processed)}</p>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        )}
                    </table>

                    {loading && (<LoadingStateComponent />
                    )}


                </div>
            </div>
            {!loading && allowance.length < 1 &&
                <EmptyStateComponent textContent={"Oops! No allowance available."} />}

            {!loading && allowance.length > 0 ? (
                <div className="">
                    <PaginationComponent
                        page={currentPage}
                        onPageChange={(page) => onPageChange({ page })}
                        totalItems={total}
                        pageSize={pageSize}
                        pageSizeShow
                        onPageSizeChange={(page) => onPageSizeChange(page)}
                    />
                </div>
            ) : (
                ''
            )}
        </>
    );
}

export default AllowancePage;