import React, { useState, useEffect } from 'react'
// useMemo, useCallback,    
//import SearchComponent from '../../components/search'
import ModalComponent from '../../components/Modals/modal'
//import moment from 'moment'
//import { debounce } from 'lodash'
import { useParams } from 'react-router-dom'
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux'
import { createApproveAllowance, listAllowanceUnderPayroll } from '../../redux/Payroll/payrollAction'
import LoadingStateComponent from '../../components/Empty State/loadingstate'
import EmptyStateComponent from '../../components/Empty State/emptystate'
import { clearApprovePayslipSuccess, clearCheckedAllowance, handleApproveAllAllowance, handleCheckedAllowance, } from '../../redux/Payroll/payroll'
import { allowanceToEdit } from '../../redux/Calculator/calculator'
import PaginationComponent from "../../components/Pagination/Pagination"


const MonthlyAllowanceTable = ({ typeID, callTypes, handleType }) => {

    const { loading, checkedAllowance, isApproved_success, allowanceUnderPayroll, total } = useSelector((state) => state.payroll)

    const dispatch = useDispatch();

    const navigate = useNavigate()

    //get id from url
    const { id } = useParams()

    const payroll_id = parseInt(id, 10) // Convert the id to an integer

    //pagination
    const [currentPage, setCurrentPage] = useState(1)
    const [pageSize, setPageSize] = useState(10);

    const onPageSizeChange = (page) => {
        setPageSize(page)
    }


    const onPageChange = (val) => {
        const { page } = val
        setCurrentPage(page)
    }

    useEffect(() => {
        dispatch(listAllowanceUnderPayroll({id: payroll_id, type: 'all', page: 1}))
    }, [dispatch, payroll_id])

    //used to check if a payslip was appproved successfully, if yes, a refresh of the unapproved 
    //list is initiated
    useEffect(() => {
        if (isApproved_success) {
            dispatch(listAllowanceUnderPayroll({ id: payroll_id, type: 'unapproved', page: 1, page_size: pageSize }))

            setTimeout(() => {
                dispatch(clearApprovePayslipSuccess())
            }, 500)
        }
    }, [dispatch, isApproved_success, payroll_id, pageSize]
    )

    // text content of the empty state of all tabs
    const textContent = (id) => {
        let res;
        switch (id) {
            case 1:
                res = 'Oops! No allowance available'
                break
            case 2:
                res = 'Oops! No unapproved allowance available.'
                break
            case 3:
                res = 'Oops! No approved allowance available'
                break;
            case 4:
                res = 'Oops! No approved allowance available'
                break;
            default:
                res = 'Oops! No allowance available.'
                break
        }
        return res;
    }

    //cta on the table for single action for all tabs except "all"
    const actionType = (id) => {
        let action;
        switch (id) {
            case 1:
                action = `No action`
                break;
            case 2:
                action = `Approve`
                break;
            case 3:
                action = `Regenerate`
                break;
            case 4:
                action = `Edit Allowance`
                break;
            default:
                action = 'None'
                break;
        }
        return action
    }

    const handleModalClick = (typeID, item) => {

        if (typeID === 2) {
            dispatch(handleCheckedAllowance({
                employee_id: item.employee.id,
                reference: item.payroll_reference
            }))
            handleApproveModal()
        } else {
            dispatch(allowanceToEdit(item))
            navigate(`edit-allowance/${item.id}`)
        }
    }

    const cancelCheckedAllowance = () => {
        dispatch(clearCheckedAllowance())
    }


    //handle search
    // const [searchValue, setSearchValue] = useState('')


    // // handle search
    // const handleSearch = useMemo(
    //     () =>
    //         debounce((val) => {
    //             //  api to dispatch and make the actions for search
    //             console.log(val);
    //         }, 750),
    //     [],
    // )

    // //handle check
    // const handleChange = useCallback(
    //     (e) => {
    //         let val = e.target.value
    //         setSearchValue(val)
    //         handleSearch(val)
    //     },
    //     [handleSearch],

    //handle the checked boxes and pass information on check from
    // the row info when it is checked - used for Approval
    const CheckedBox = (employeeid, ref) => {
        dispatch(handleCheckedAllowance({
            employee_id: employeeid,
            reference: ref,
        }))
    }

    //modal for single approval
    const [approvePayslip, setApprovePayslip] = useState(false)

    const handleApproveModal = (val) => {
        setApprovePayslip(true)
    }

    const handleApprovePayslip = () => {
        dispatch(createApproveAllowance({ payroll_id }))
        setApprovePayslip(false)
    }

    //approve all allowance functionality
    const [selectAllChecked, setSelectAllChecked] = useState(false);
    let allPayslips = [];

    const handleSelectAllCheckbox = () => {
        setSelectAllChecked(prevState => !prevState);

        if (!selectAllChecked && typeID === 2) {
            allPayslips = allowanceUnderPayroll.map(payslip => ({
                employee_id: payslip.employee.id,
                reference: payslip.payroll_reference,
            }))
            dispatch(handleApproveAllAllowance(allPayslips))
        } else {
            dispatch(clearCheckedAllowance())
        }
    }
    useEffect(() => {
        // Check if all items are checked, and update selectAllChecked state accordingly
        if (typeID === 2) {
            setSelectAllChecked(checkedAllowance.length === allowanceUnderPayroll.length)
        }
        if (checkedAllowance.length === 0 && allowanceUnderPayroll.length === 0) {
            setSelectAllChecked(false)
        }
    }, [typeID, checkedAllowance, allowanceUnderPayroll]);



    return (
        <>
            < ModalComponent
                title={`Approve Payslip`}
                show={approvePayslip}
                showModal={() => {
                    setApprovePayslip(false);
                    //cancelCheckedAllowance()
                }}
                subtitle={`Are you sure you want to approve this/ these payslips?`}
            >
                <div className="pt-[32px] flex justify-end gap-[40px] items-center mb-[12px]">
                    <button
                        onClick={() => {
                            setApprovePayslip(false);
                            cancelCheckedAllowance()
                        }}
                        className="text-sm text-medium leading-5 text-[#5C715E]">No</button>
                    <button
                        onClick={() => handleApprovePayslip()}
                        className="outline-0 bg-primary rounded-[8px] w-[62px] h-[40px] text-sm text-medium leading-5 text-white">
                        Yes
                    </button>
                </div>
            </ModalComponent>
            <div className="mt-4 bg-white rounded-[8px]">
                <div className="p-4">
                    {/* search */}
                    {/* <div className="mt-4">
                    <SearchComponent
                        placeholder="Search..."
                        searchValue={searchValue}
                        handleChange={handleChange}
                    />
                </div> */}

                    <ul className="flex mt-5 gap-5 items-center">
                        {callTypes.map((item) => (
                            <li
                                key={item.id}
                                onClick={() => handleType(item.id, item.tag)}
                                className={
                                    typeID === item.id
                                        ? 'flex gap-1 items-center border-b-2 border-primary pb-1 cursor-pointer'
                                        : 'flex gap-2 items-center pb-1 cursor-pointer'
                                }
                            >
                                <p
                                    className={
                                        typeID === item.id
                                            ? 'text-primary text-sm font-semibold'
                                            : 'text-[#96A397] text-sm font-semibold'
                                    }
                                >
                                    {item.title}
                                </p>
                            </li>
                        ))}
                    </ul>
                </div>

                {/* tables */}
                <div className="overflow-x-auto relative">
                    <table className="w-full text-left">
                        <thead className="text-primary text-sm border-b-[#F7F7F7] border-b-2">
                            <tr>
                                {typeID !== 1 && typeID !== 4 && typeID !== 3 && <th scope="col" className="px-4 py-4 font-semibold ">
                                    <input
                                        type="checkbox"
                                        checked={selectAllChecked}
                                        onChange={() => handleSelectAllCheckbox()}
                                    />
                                </th>}
                                <th scope="col" className="px-4 py-4 font-semibold ">
                                    Employee ID
                                </th>
                                <th scope="col" className="px-4 py-4 font-semibold">
                                    First Name
                                </th>
                                <th scope="col" className="px-4 py-4 font-semibold">
                                    Last Name
                                </th>
                                <th scope="col" className="px-4 py-4 font-semibold">
                                    Allowance
                                </th>
                                <th scope="col" className="px-4 py-4 font-semibold">
                                    Payslip Status
                                </th>

                                {typeID !== 1 && typeID !== 3 && <th scope="col" className="px-4 py-4 font-semibold">
                                    Action
                                </th>}
                            </tr>
                        </thead>
                        {!loading && (
                            <tbody>
                                {allowanceUnderPayroll.length > 0 &&
                                    allowanceUnderPayroll.map((item) => (
                                        <tr
                                            key={item.id}
                                            className="bg-white text-sm cursor-pointer hover:bg-[#EDF7EE]"
                                        >
                                            {typeID !== 1 && typeID !== 4 && typeID !== 3 && <th
                                                scope="row"
                                                className="py-4 px-4 font-normal text-[#5C715E]"
                                            >
                                                <input type="checkbox"
                                                    checked={checkedAllowance.some(val => val.reference === item.payroll_reference)}
                                                    onChange={() => CheckedBox(item.employee.id, item.payroll_reference)}
                                                    name="approved_allowance_child" />
                                            </th>
                                            }

                                            <td className="py-4 px-4 text-secondary1">
                                                {item.employee ? item.employee.employee_id : "N/A"}
                                            </td>
                                            <td className="py-4 px-4 text-secondary1">
                                                {item.employee ? item.employee.first_name : "N/A"}
                                            </td>
                                            <td className="py-4 px-4 text-secondary1">
                                                {item.employee ? item.employee.last_name : "N/A"}
                                            </td>
                                            <td className="py-4 px-4 text-secondary1">
                                                {item.allowance_payable ? `₦${item.allowance_payable?.toLocaleString()}` : "N/A"}
                                            </td>
                                            <td className="py-4 px-4 text-secondary1">
                                                <div className="flex">
                                                    <div className={item.approved ? 'success-badge' : 'pending-badge'}>
                                                        <p>{item.approved ? 'Approved' : 'Unapproved'}</p>
                                                    </div>
                                                </div>
                                            </td>
                                            {typeID !== 1 && typeID !== 3 &&
                                                <td className="py-4 px-4 text-secondary1 ">
                                                    <button
                                                        disabled={checkedAllowance.length > 1}
                                                        onClick={() => handleModalClick(typeID, item)}
                                                        className={typeID !== 3 ? "bg-transparent disabled:bg-[#ABB6AC] border-[1px] rounded-lg py-2.5 px-3 text-primary border-primary font-medium hover:bg-[#24752B] hover:text-bold hover:text-white transition-all text-sm" : "bg-primary disabled:bg-[#ABB6AC] rounded-lg py-2.5 px-3 text-white border-primary font-medium hover:bg-[#24752B] hover:text-bold hover:text-white transition-all text-sm"}>
                                                        <p>{actionType(typeID)}</p>
                                                    </button>
                                                </td>
                                            }
                                        </tr>
                                    ))
                                }
                            </tbody>
                        )}
                    </table>

                    {loading && (
                        <LoadingStateComponent />
                    )}
                </div>
            </div>
            {!loading && allowanceUnderPayroll.length < 1 &&
                <EmptyStateComponent textContent={textContent(typeID)} />
            }
            {!loading && allowanceUnderPayroll.length > 0 ? (
                <div className="">
                    <PaginationComponent
                        page={currentPage}
                        onPageChange={(page) => onPageChange({ page })}
                        totalItems={total}
                        pageSize={pageSize}
                        pageSizeShow
                        onPageSizeChange={(page) => onPageSizeChange(page)}
                    />
                </div>
            ) : (
                ''
            )}
        </>
    );
}

export default MonthlyAllowanceTable;