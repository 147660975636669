import { createSlice } from '@reduxjs/toolkit'
import { createRequest, PaymentTable, paySalaryAdvance, updateSalaryAdvance } from './advanceAction'

const initialState = {
    value: 0,
    loading: false,
    loader: false,
    requests: [],
    total: 0,
    is_pay_advance : false,
    payments: [],
    is_request_success: false,
    is_updated_success: false
}

export const advanceSlice = createSlice({
    name: 'advance',
    initialState,
    reducers: {
        decrement: (state) => {
            state.value -= 1
        },
        incrementByAmount: (state, action) => {
            state.value += action.payload
        },
        clearCreateRequest: (state) => {
            state.is_request_success = false
        },
        clearUpdateRequestStatus: (state) => {
            state.is_updated_success = false
        }
    },
    extraReducers: {
        //get admin payment table --------------
        [PaymentTable.pending]: (state) => {
            state.loading = true
            state.loader = true
        },
        [PaymentTable.fulfilled]: (state, { payload }) => {
            state.loading = false
            state.loader = false
            state.total = payload.count
            state.payments = payload.data
        },
        [PaymentTable.rejected]: (state) => {
            state.loading = false
            state.loader = false
        },
        //pay salary advace status ------------------------------------------------------------------------------------
        [paySalaryAdvance.pending]: (state) => {
            state.loading = true
            state.is_pay_advance = false
        },
        [paySalaryAdvance.fulfilled]: (state) => {
            state.loading = false
            state.is_pay_advance = true
        },
        [paySalaryAdvance.rejected]: (state) => {
            state.loading = false
            state.is_updated = false
        },
        //create salary advance request
        [createRequest.pending]: (state) => {
            state.loading = true
            state.is_request_success = false
        },
        [createRequest.fulfilled]: (state) => {
            state.loading = false
            state.is_request_success = true
        },
        [createRequest.rejected]: (state) => {
            state.loading = false
            state.is_request_success = false
        },
        //update single salary advance
        [updateSalaryAdvance.pending]: (state) => {
            state.loading = true
            state.is_updated_success = false
        },
        [updateSalaryAdvance.fulfilled]: (state) => {
            state.loading = false
            state.is_updated_success = true
        },
        [updateSalaryAdvance.rejected]: (state) => {
            state.loading = false
            state.is_updated_success = false
        }  
    }
})

// Action creators are generated for each case reducer function
export const { decrement, incrementByAmount, clearCreateRequest, clearUpdateRequestStatus } = advanceSlice.actions
export default advanceSlice.reducer
