import React, { useState, useEffect } from "react";
import EmptyStateComponent from "../../components/Empty State/emptystate";
import moment from "moment";
import LoadingStateComponent from "../../components/Empty State/loadingstate";
import DrawerComponent from "../../components/Drawer/drawer";
import { useDispatch, useSelector } from "react-redux";
import {
  disbursePayout,
  downloadPensionPayoutReceipt,
  pensionPayout,
  updatePensionPayout
} from "../../redux/Pension/pensionAction";
import ModalComponent from "../../components/Modals/modal";
//import PaginationComponent from "../../components/Pagination/Pagination";

const PensionPayoutTable = () => {
  //navigate to individual employees
  // const navigate = useNavigate()
  // const handleNavigateToPayPartner = (id) => {
  //     navigate(`/pension-payout/pay-partner`)
  // }

  const { loading, payouts} = useSelector((state) => state.pension);
 // console.log("rerender1");
  //payout id state
  const [payoutId, setPayoutId] = useState(0);

  let payout;

  //let payout = payouts;
  payout = payouts.find((payout) => payout.id === payoutId);

  //functionality for drawer component
  //open drawer
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  //close drawer
  const closePayoutInfo = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  const toggleDrawer = (id) => {
    setPayoutId(id);
    setIsDrawerOpen(!isDrawerOpen);
  };

  // let total = payouts.length;
  // console.log(payouts, total);
  const handleReceiptDownload = (val) => {
    setIsDrawerOpen(false);
    // let res = {
    //   id: payslip.id,
    //   date: moment(payslip.date_of_entry).format('YYYY-MM-DD'),
    //   payslip: `${payslip.employee.first_name} ${payslip.employee.last_name} -  ${moment(payslip.date_of_entry).format("MMMM")}  ${moment(payslip.date_of_entry).format("YYYY")}`
    // }
    // dispatch(downloadReceipt(res))
  };

  const dispatch = useDispatch();

    //state to manage download
    const [isDownload, setIsDownload] = useState(false);

  useEffect(() => {
    dispatch(
      pensionPayout({
        page: 1,
      })
    );
  }, [dispatch, isDownload]);


  

  //pagination
  // const [currentPage, setCurrentPage] = useState(1);

  // const onPageChange = (val) => {
  //   const { page } = val;
  //   setCurrentPage(page);
  //   dispatch(
  //     pensionPayout({
  //       page: page,
  //     })
  //   );
  // };
  //status


  const getPaymentStatusBg = (val) => {
    let result;
    switch (val) {
      case "FAILED":
        result = "error-badge";
        break;
      case "PROCESSING":
        result = "dispatched-badge";
        break;
      case "COMPLETED":
        result = "success-badge";
        break;
      case "PENDING":
        result = "pending-badge";
        break;
      case "DONE":
        result = "done-badge";
        break;
      case "PAY":
        result = "pay-badge";
        break;
      default:
        result = "dispatched-badge";
        break;
    }
    return result;
  };

  const getPaymentStatusText = (val) => {
    let result;
    switch (val) {
      case "PROCESSING":
        result = "Processing";
        break;
      case "FAILED":
        result = "Failed";
        break;
      case "PENDING":
        result = "Pending";
        break;
      case "COMPLETED":
        result = "Completed";
        break;
      default:
        result = "Processing";
        break;
    }
    return result;
  };

  //modal componenet
  const [showModal, setShowModal] = useState(false);

  const toggleModal = () => {

    setShowModal(!showModal);
    setIsDrawerOpen(false);
  };

  //disburse payout on click pay
  const handleDisbursePayout = (val) => {
    dispatch(disbursePayout({ id: val }));
  }


  const handleDownloadPayoutReceipt = () => {
    if (payout) { 
      dispatch(downloadPensionPayoutReceipt({ id: payout.id, contribution_date: moment(payout.contribution_date).format('MMMM YYYY') }));
     }
     setIsDownload(true);
  }

  const [updatedAmount, setUpdatedAmount] = useState(payout ? payout.amount : 0); // State for the amount

  const handleAmountChange = (event) => {
    const newAmount = event.target.value;
    setUpdatedAmount(newAmount);
  };

  const updatePayout = ({ id, amount }) => {

    // Call your existing updatePensionPayout with the updatedAmount
    dispatch(updatePensionPayout({id: payout.id, amount: updatedAmount}));
    //dispatch update payout
    dispatch(pensionPayout({ page: 1 }));
    // Close the modal
    setShowModal(false); 
  };

  return (
    <>
      <DrawerComponent
        title="Payout Info"
        show={isDrawerOpen}
        showDrawer={closePayoutInfo}
      >
        <div className="mt-6">
          <div className="flex justify-between items-center">
            <div>
              <p className="text-[14px] leading-[18px] text-secondary1 font-medium">
                Partner Account Name
              </p>
            </div>
            <div>
              <p className="text-[14px] leading-[18px] text-secondary1 font-medium">
                {payout && payout.pfa ? payout.pfa.account_name : "N/A"}
              </p>
            </div>
          </div>
        </div>
        <div className="mt-6">
          <div className="flex justify-between items-center">
            <div>
              <p className="text-[14px] leading-[18px] text-secondary1 font-medium">
                Partner Bank Name
              </p>
            </div>
            <div>
              <p className="text-[14px] leading-[18px] text-secondary1 font-medium">
                {payout && payout.pfa ? payout.pfa.name : "N/A"}
              </p>
            </div>
          </div>
        </div>
        <div className="mt-6">
          <div className="flex justify-between items-center">
            <div>
              <p className="text-[14px] leading-[18px] text-secondary1 font-medium">
                Partner Account Number
              </p>
            </div>
            <div>
              <p className="text-[14px] leading-[18px] text-secondary1 font-medium">
                {payout && payout.pfa ? payout.pfa.account_number : "N/A"}
              </p>
            </div>
          </div>
        </div>
        <div className="mt-6">
          <div className="flex justify-between items-center">
            <div>
              <p className="text-[14px] leading-[18px] text-secondary1 font-medium">
                Source Account
              </p>
            </div>
            <div>
              <p className="text-[14px] leading-[18px] text-secondary1 font-medium">
                Crop2Cash Limited
                {/* No source account field - hardcoded */}
              </p>
            </div>
          </div>
        </div>
        <div className="mt-6">
          <div className="flex justify-between items-center">
            <div>
              <p className="text-[14px] leading-[18px] text-secondary1 font-medium">
                Source Account Number
              </p>
            </div>
            <div>
              <p className="text-[14px] leading-[18px] text-secondary1 font-medium">
                123*****678
                {/* No source account information - hardcoded */}
              </p>
            </div>
          </div>
        </div>
        <div className="mt-6">
          <div className="flex justify-between items-center">
            <div>
              <p className="text-[14px] leading-[18px] text-secondary1 font-medium">
                Payout On
              </p>
            </div>
            <div>
              <p className="text-[14px] leading-[18px] text-secondary1 font-medium">
                {payout && payout.pfa
                  ? `${moment(payout.contribution_date).format(
                    "DD MMMM, YYYY"
                  )}`
                  : "N/A"}
              </p>
            </div>
          </div>
        </div>
        <div className="mt-6">
          <div className="flex justify-between items-center">
            <div>
              <p className="text-[14px] leading-[18px] text-secondary1 font-medium">
                Time
              </p>
            </div>
            <div>
              <p className="text-[14px] leading-[18px] text-secondary1 font-medium">
                {payout && payout.creation_date
                  ? moment(payout.creation_date)
                    .utcOffset("+01:00")
                    .format("h:mm A")
                  : "N/A"}
              </p>
            </div>
          </div>
        </div>
        <div className="mt-6">
          <div className="flex justify-between items-center">
            <div>
              <p className="text-[14px] leading-[18px] text-secondary1 font-medium">
                Transaction Initiated By
              </p>
            </div>
            <div>
              <p className="text-[14px] leading-[18px] text-secondary1 font-medium">
                {payout && payout.creation_by ? payout.created_by : "N/A"}
              </p>
            </div>
          </div>
        </div>
        <div className="mt-6">
          <div className="flex justify-between items-center">
            <div>
              <p className="text-[14px] leading-[18px] text-secondary1 font-medium">
                Transaction Description
              </p>
            </div>
            <div>
              <p className="text-[14px] leading-[18px] text-secondary1 font-medium">
                {payout && payout.description ? payout.description : "N/A"}
              </p>
            </div>
          </div>
        </div>
        <div className="mt-6">
          <div className="flex justify-between items-center">
            <div>
              <p className="text-[16px] leading-5 text-primary font-semibold">
                Payout Amount
              </p>
            </div>
            <div>
              <p className="text-[18px] leading-5 text-primary font-semibold">
                {payout && payout.amount
                  ? `₦${payout.amount?.toLocaleString()}`
                  : 0}
              </p>
            </div>
          </div>
        </div>
        <div className="mt-6 flex flex-row items-center justify-between ">
          <div onClick={toggleModal}>
            <button className="outline-none bg-white border border-primary text-primary font-medium text-sm py-[10px] px-5 rounded-lg hover:bg-primaryhover hover:border hover:border-primary hover:text-white transition ease-in-out delay-100">
              Pay
            </button>
          </div>
          <div onClick={() => handleReceiptDownload()}>
            <button className="outline-none bg-primary border border-primary text-white font-medium text-sm py-[10px] px-5 rounded-lg hover:bg-primaryhover hover:border hover:border-primary hover:text-white transition ease-in-out delay-100" onClick={() => {setIsDownload(true); handleDownloadPayoutReceipt()}}>
              Download Receipt
            </button>
          </div>
        </div>
      </DrawerComponent>
      <ModalComponent
        show={showModal}
        showModal={() => setShowModal(false)}
        title="Process Payment"
        subtitle="Are you sure you sure you want to pay pension of 30 employees? Please review the details below and click on proceed."
      >
        <div className="flex flex-col gap-2">
          <div className="mt-2 flex gap-2 items-center justify-between">
            <p>Contribution Month and Year</p>
            <p>
              {" "}
              {payout && payout.pfa
                ? `${moment(payout.contribution_date).format("MMMM, YYYY")}`
                : "N/A"}
            </p>
          </div>
          <div className="mt-2 flex gap-2 items-center justify-between">
            <p>No. of Employees Contribution</p>
            <p>
              {" "}
              {payout && payout.employee_count ? payout.employee_count : 0}
            </p>
          </div>
          <div className="mt-2 flex gap-2 items-center justify-between">
            <p>Partner Name</p>
            <p> {payout && payout.pfa ? payout.pfa.name : "N/A"}</p>
          </div>
          <div className="mt-2 flex gap-2 items-center justify-between">
            <p>Partner Bank</p>
            <p> {payout && payout.pfa.bank ? payout.pfa.bank : "N/A"}</p>
          </div>
          <div className="mt-2 flex gap-2 items-center justify-between">
            <p>Partner Account Number</p>
            <p> {payout && payout.pfa ? payout.pfa.account_number : "N/A"}</p>
          </div>
          <div className="mt-2 flex gap-2 items-center justify-between">
            <p>Partner Account Name</p>
            <p> {payout && payout.pfa ? payout.pfa.account_name : "N/A"}</p>
          </div>
          <div className="mt-2 flex gap-2 items-center justify-between">
            <p>Payout Amount</p>
            <p> {payout ? payout.amount : "N/A"}</p>
          </div>
          <div className="mt-2 flex gap-2 items-center justify-between">
            <p>Update Payout Amount</p>
            <input 
              type="number"
              inputMode="decimal"
              step={0.01}
              value={updatedAmount} 
              onChange={handleAmountChange} 
              className="border rounded-md px-3 py-2 w-32" 
            />
          </div>

          <div className="flex flex-row justify-end gap-2 mt-4">
            <div onClick={() =>  updatePayout({id: payout.id, amount: updatedAmount})}>
              <button className="outline-none bg-white border border-primary text-primary font-medium text-sm py-[10px] px-5 rounded-lg hover:bg-primaryhover hover:border hover:border-primary hover:text-white transition ease-in-out delay-100">
                Update Amount
              </button>
            </div>
            <div onClick={() => handleDisbursePayout(payout.id)}>
              <button className="outline-none bg-primary border border-primary text-white font-medium text-sm py-[10px] px-5 rounded-lg hover:bg-primaryhover hover:border hover:border-primary hover:text-white transition ease-in-out delay-100">
                Pay
              </button>
            </div>

          </div>
        </div>
      </ModalComponent>
      {/* table description */}
      <div className="mt-4 bg-white rounded-[8px]">
        <div className="p-4">
          <h5 className="text-primary font-semibold text-base leading-5">
            Pension Payment Records
          </h5>
          <p className="text-[#7C8D7E] font-medium text-sm">
            This table shows a summary of pension payment made monthly.
          </p>
        </div>
        <div className="overflow-x-auto relative">
          <table className="w-full text-left">
            <thead className="text-primary text-sm border-b-[#F7F7F7] border-b-2">
              <tr>
                <th scope="col" className="px-4 py-4 font-semibold ">
                  Date of Payment
                </th>
                <th scope="col" className="px-4 py-4 font-semibold">
                  Contribution Month
                </th>
                <th scope="col" className="px-4 py-4 font-semibold">
                  No. of Employees
                </th>
                <th scope="col" className="px-4 py-4 font-semibold">
                  Amount Paid
                </th>
                <th scope="col" className="px-4 py-4 font-semibold">
                  Pension Partner
                </th>
                <th scope="col" className="px-4 py-4 font-semibold">
                  Payment Status
                </th>
              </tr>
            </thead>
            {!loading && (
              <tbody>
                {payouts.length > 0 &&
                  payouts.map((item) => (
                    <tr
                      key={item.id}
                      onClick={() => toggleDrawer(item.id)}
                      className="bg-white cursor-pointer text-sm hover:bg-[#EDF7EE]"
                    >
                      <th
                        scope="row"
                        className="py-4 px-4 font-normal text-[#5C715E] whitespace-nowrap "
                      >
                        {moment(payouts.contribution_date).format(
                          "MMMM DD, YYYY"
                        )}
                      </th>
                      <td className="py-4 px-4 text-[#5C715E]">
                        {moment(item.contribution_date).format("MMMM")}
                      </td>
                      <td className="py-4 px-4 text-[#5C715E]">
                        {item.employee_count}
                      </td>
                      <td className="py-4 px-4 text-[#5C715E]">
                        {item.amount}
                      </td>
                      <td className="py-4 px-4 text-[#5C715E]">
                        {item.pfa ? item.pfa.name : "-"}
                      </td>
                      <td className="py-4 px-4 text-[#5C715E]">
                        <div className="flex">
                          <div className={getPaymentStatusBg(item.status)}>
                            <p>{getPaymentStatusText(item.status)}</p>
                          </div>
                        </div>
                      </td>
                    </tr>
                  ))}
                {/* <tr
                  key={item.id}
                  onClick={() => toggleDrawer(payouts.id)}
                  className="bg-white cursor-pointer text-sm hover:bg-[#EDF7EE]"
                >
                 {payouts.length > 0 && 
                  payouts.map((item) => ( 
                  <th
                    scope="row"
                    className="py-4 px-4 font-normal text-[#5C715E] whitespace-nowrap "
                  >
                    {moment(payouts.contribution_date).format("MMMM DD, YYYY")}
                  </th>

                  <td className="py-4 px-4 text-[#5C715E]">
                    {moment(payouts.contribution_date).format("MMMM")}
                  </td>
                  <td className="py-4 px-4 text-[#5C715E]">
                    {payouts.employee_count}
                  </td>
                  <td className="py-4 px-4 text-[#5C715E]">{payouts.amount}</td>
                  <td className="py-4 px-4 text-[#5C715E]">
                    {payouts.pfa ? payouts.pfa.name : "N/A"}
                  </td>
                  <td className="py-4 px-4 text-[#5C715E]">
                    <div className="flex">
                      <div className={getPaymentStatusBg(payouts.status)}>
                        <p>{getPaymentStatusText(payouts.status)}</p>
                      </div>
                    </div>
                  </td> */}
              </tbody>
            )}
          </table>

          {loading && <LoadingStateComponent />}
        </div>
      </div>

      {!loading && payouts.length < 1 && (
        <EmptyStateComponent textContent={"Oops! No payouts available"} />
      )}

      {/* {!loading && payouts.length > 0 ? (
        <div className="">
          <PaginationComponent
            page={currentPage}
            onPageChange={(page) => onPageChange({ page })}
            pageSize={10}
            totalItems={total}
          />
        </div>
      ) : (
        ""
      )} */}
    </>
  );
};

export default PensionPayoutTable;
