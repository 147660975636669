import React, { useState } from "react";
import logo from "../assets/c2c-logo.svg";
import DashboardIcon from "@mui/icons-material/Dashboard";
import ReceiptIcon from "@mui/icons-material/Receipt";
import EmployeeIcon from "../assets/profile-2user.svg";
import EmployeeIconActive from "../assets/profile-2user-active.svg";
//import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import { Comment } from "@mui/icons-material";
// import Notifications from "../assets/notifications.svg";
import Chevron_Down from "../assets/chevron_down.svg";
import Chevron_Up from "../assets/chevron_up.svg";
import money_wave from "../assets/money-bill-wave.svg";
import money_wave_active from "../assets/money_wave_active.svg";
import logout_icon from "../assets/logout.svg";
import { NavLink, useLocation, useNavigate, Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Logout } from "../redux/Auth/auth";
import record_icon from "../assets/save-2.svg"
import record_white from "../assets/save_white.svg"
import advance_icon from "../assets/salary_advance.svg"
import advance_white from "../assets/advance.svg"
//import pension_payout_icon from "../assets/dashboard.svg"
//import pension_payout_icon_white from "../assets/dashboard copy.svg"

const SideBar = ({ children, title }) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { is_admin, first_name, last_name } = useSelector(
    (state) => state.auth
  );
  const [isEmployeeHover, setisEmployeeHover] = useState(false);
  const [isPensionHover, setisPensionHover] = useState(false);
  // const [isPensionPayoutHover, setisPensionPayoutHover] = useState(false);
  const [isRecordHover, setisRecordHover] = useState(false);

  //logout functionaility on nav section
  const [isNavMenu, setNavMenu] = React.useState(false);
  const toggleNavMenu = () => {
    setNavMenu(!isNavMenu);
  };

  const handleLogout = () => {
    dispatch(Logout());
    navigate("/");
  };
  return (
    <>
      <div className="flex h-screen bg-[#f5f5f5]">
        <div className="md:w-64 bg-primary flex-shrink-0 pt-[30px] sidebar_scroll overflow-y-scroll">
          <img
            src={logo}
            alt="logo"
            className="pl-[26.6px]  overflow-y-scroll"
          />

          <div className=" cursor-pointer mt-[40px]">
            {/* OVERVIEW AND NOTIFICATIONS SECTION */}
            {is_admin && (
              <NavLink
                to="/overview"
                className={
                  location.pathname.includes("/overview") || location.pathname.includes("view-profile")
                    ? "pl-[26.6px] flex gap-[18.5px] items-center  mb-[13px] py-[13px]  text-primary bg-white w-[98%] rounded-r-[8px]"
                    : "pl-[26.6px] flex gap-[18.5px] items-center  mb-[13px] py-[13px]  text-white hover:text-primary hover:bg-white w-[98%] rounded-r-[8px]"
                }
              >
                <DashboardIcon style={{ fontSize: "18px" }} />
                <p className="font-semibold text-sm capitalize leading-[18px]">
                  Overview
                </p>
              </NavLink>
            )}

            {is_admin && (
              <NavLink
                to="/payroll"
                className={
                  location.pathname.includes("payroll")
                    ? "flex justify-between items-center text-primary bg-white  mb-[13px] py-[13px] w-[98%] rounded-r-[8px]"
                    : "flex justify-between items-center hover:text-primary text-white hover:bg-white mb-[13px] py-[13px] w-[98%] rounded-r-[8px]"
                }
              >
                <div className="pl-[26.6px] flex gap-[18.5px] items-center font-semibold text-sm capitalize leading-[18px]">
                  <ReceiptIcon style={{ fontSize: "18px" }} />
                  <p className="">Payroll</p>
                </div>
              </NavLink>
            )}

            {is_admin && (
              <NavLink
                onMouseEnter={() => setisEmployeeHover(true)}
                onMouseLeave={() => setisEmployeeHover(false)}
                to="/employees"
                className={
                  location.pathname.includes("/employee") || location.pathname.includes("/edit-employee") || location.pathname.includes("/add-employee-record")
                    ? "pl-[26.6px] flex gap-[18.5px] items-center  mb-[13px] py-[13px]  text-primary bg-white w-[98%] rounded-r-[8px]"
                    : "pl-[26.6px] flex gap-[18.5px] items-center  mb-[13px] py-[13px]  text-white hover:text-primary hover:bg-white w-[98%] rounded-r-[8px]"
                }
              >
                <img
                  src={
                    location.pathname.includes("/employee") || isEmployeeHover || location.pathname.includes("/edit-employee") || location.pathname.includes("/add-employee-record")
                      ? EmployeeIconActive
                      : EmployeeIcon
                  }
                  alt="employee-icon"
                />
                <p className="font-semibold text-sm capitalize leading-[18px]">
                  Employees
                </p>
              </NavLink>
            )}



            {/* Employee Dashboard Navlink */}
            {!is_admin && (
              <NavLink
                to="/payslip"
                className={
                  location.pathname.includes("payslip") || location.pathname.includes("view-profile")
                    ? "flex justify-between items-center text-primary bg-white  mb-[13px] py-[13px] w-[98%] rounded-r-[8px]"
                    : "flex justify-between items-center hover:text-primary text-white hover:bg-white mb-[13px] py-[13px] w-[98%] rounded-r-[8px]"
                }
              >
                <div className="pl-[26.6px] flex gap-[18.5px] items-center font-semibold text-sm capitalize leading-[18px]">
                  <ReceiptIcon style={{ fontSize: "18px" }} />
                  <p className="">Payslip</p>
                </div>
              </NavLink>
            )}
            {!is_admin && (
              <NavLink
                to="/allowance"
                className={
                  location.pathname.includes("allowance")
                    ? "flex justify-between items-center text-primary bg-white  mb-[13px] py-[13px] w-[98%] rounded-r-[8px]"
                    : "flex justify-between items-center hover:text-primary text-white hover:bg-white mb-[13px] py-[13px] w-[98%] rounded-r-[8px]"
                }
              >
                <div className="pl-[26.6px] flex gap-[18.5px] items-center font-semibold text-sm capitalize leading-[18px]">
                  <ReceiptIcon style={{ fontSize: "18px" }} />
                  <p className="">Allowance</p>
                </div>
              </NavLink>
            )}
            <NavLink
              to="/complaints"
              className={
                location.pathname.includes("complaints")
                  ? "flex justify-between items-center text-primary bg-white  mb-[13px] py-[13px] w-[98%] rounded-r-[8px]"
                  : "flex justify-between items-center hover:text-primary text-white hover:bg-white mb-[13px] py-[13px] w-[98%] rounded-r-[8px]"
              }
            >
              <div className="pl-[26.6px] flex gap-[18.5px] items-center font-semibold text-sm capitalize leading-[18px]">
                <Comment style={{ fontSize: "18px" }} />
                <p className="">Complaints</p>
              </div>
            </NavLink>

            <NavLink
              to="/salary-advance"
              className={
                location.pathname.includes("salary-advance" || "advance" || "view-advance" || "salary advance")
                  ? "flex justify-between items-center text-primary bg-white  mb-[13px] py-[13px] w-[98%] rounded-r-[8px]"
                  : "flex justify-between items-center hover:text-primary text-white hover:bg-white mb-[13px] py-[13px] w-[98%] rounded-r-[8px]"
              }
            >
              <div className="pl-[26.6px] flex gap-[18.5px] items-center font-semibold text-sm capitalize leading-[18px]">
                <img
                  src={
                    location.pathname.includes("/salary-advance" || "advance" || "view-advance" || "salary advance")
                      ? advance_white
                      : advance_icon
                  }
                  alt="salary-advance-icon"
                />
                <p className="">Salary Advance</p>
              </div>
            </NavLink>

            {is_admin && (
              <NavLink
                onMouseEnter={() => setisPensionHover(true)}
                onMouseLeave={() => setisPensionHover(false)}
                to="/pension"
                className={
                  location.pathname.includes("/pension")
                    ? "pl-[26.6px] flex gap-[18.5px] items-center  mb-[13px] py-[13px]  text-primary bg-white w-[98%] rounded-r-[8px]"
                    : "pl-[26.6px] flex gap-[18.5px] items-center  mb-[13px] py-[13px]  text-white hover:text-primary hover:bg-white w-[98%] rounded-r-[8px]"
                }
              >
                <img
                  src={
                    location.pathname.includes("/pension") || isPensionHover
                      ? money_wave_active
                      : money_wave
                  }
                  alt="Pension icon"
                  className=""
                />
                <p className="font-semibold text-sm capitalize leading-[18px]">
                  Pension
                </p>
              </NavLink>
            )}

            {/* {is_admin && (
              <NavLink
                onMouseEnter={() => setisPensionPayoutHover(true)}
                onMouseLeave={() => setisPensionPayoutHover(false)}
                to="/pension-payout"
                className={
                  location.pathname.includes("/pension-payout")
                    ? "pl-[26.6px] flex gap-[18.5px] items-center  mb-[13px] py-[13px]  text-primary bg-white w-[98%] rounded-r-[8px]"
                    : "pl-[26.6px] flex gap-[18.5px] items-center  mb-[13px] py-[13px]  text-white hover:text-primary hover:bg-white w-[98%] rounded-r-[8px]"
                }
              >
                <img
                  src={
                    location.pathname.includes("/pension-payout") || isPensionPayoutHover
                      ? pension_payout_icon
                      : pension_payout_icon_white
                  }
                  alt="Pension  Payout icon"
                  className=""
                />
                <p className="font-semibold text-sm capitalize leading-[18px]">
                  Pension Payout
                </p>
              </NavLink>
            )} */}



            {is_admin && (
              <NavLink
                onMouseEnter={() => setisRecordHover(true)}
                onMouseLeave={() => setisRecordHover(false)}
                to="/records"
                className={
                  location.pathname.includes("/record")
                    ? "pl-[26.6px] flex gap-[18.5px] items-center  mb-[13px] py-[13px]  text-primary bg-white w-[98%] rounded-r-[8px]"
                    : "pl-[26.6px] flex gap-[18.5px] items-center  mb-[13px] py-[13px]  text-white hover:text-primary hover:bg-white w-[98%] rounded-r-[8px]"
                }
              >
                <img
                  src={
                    location.pathname.includes("/record") || isRecordHover
                      ? record_icon
                      : record_white
                  }
                  alt="Record icon"
                  className=""
                />
                <p className="font-semibold text-sm capitalize leading-[18px]">
                  Records
                </p>
              </NavLink>
            )}




          </div>

        </div>

        <div className="py-4 flex-grow flex-auto flex-shrink overflow-y-scroll">
          {/* NAV BAR */}
          <div className="flex px-4 md:px-6 justify-between items-center">
            <div className="text-secondary font-semibold text-xsm">{title}</div>
            <div className="flex gap-4 items-center">
              {/* <div className="bg-[#2B8C34] flex justify-center items-center w-[32px] h-[32px] rounded-full relative">
                <img src={Notifications} alt="notification" />
                <div className="bg-[#B92043] w-[10px] h-[10px] rounded-full absolute -right-1 top-1 border-white border-2"></div>
              </div> */}
              <div className="bg-[#2B8C34] flex justify-center items-center w-[32px] h-[32px] rounded-full">
                <p className="text-white text-xs font-medium">
                  {first_name.charAt(0).toUpperCase()}
                  {last_name.charAt(0).toUpperCase()}
                </p>
              </div>
              <div
                className="flex items-center gap-2 cursor-pointer relative"
                onClick={toggleNavMenu}>
                <p className="text-secondary1 text-sm font-semibold">
                  {first_name ? first_name : ""}{" "}
                  {last_name ? last_name : ""}
                </p>
                <img src={isNavMenu ? Chevron_Up : Chevron_Down} alt={isNavMenu ? "arrow_up" : "arrow_down"} />
                <ul
                  className={
                    isNavMenu
                      ? "absolute top-10 transition-all right-0 bg-[#fff] rounded-[10px] min-w-[162px] border shadow-drop z-10"
                      : "absolute hidden transition-all top-20 right-0 bg-[#fff] rounded-[10px] min-w-[162px] border shadow-drop"
                  }
                >
                  <Link
                    to="/view-profile"
                    className="px-6 py-3 hover:bg-[#EDF7EE] flex hover:rounded-tr-[10px] hover:rounded-tl-[10px] transition-all"
                  >
                    <p className="text-[#344335] text-sm font-medium">
                      View Profile
                    </p>
                  </Link>
                  <li
                    onClick={handleLogout}
                    className="px-6 py-3 hover:bg-[#EDF7EE] hover:rounded-bl-[10px] hover:rounded-br-[10px] transition-all flex item-center gap-2"
                  >
                    <img src={logout_icon} alt="logout" />
                    <p className="text-[#344335] text-sm font-medium">Log Out</p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="px-4 md:px-6 py-6">{children}</div>
        </div>
      </div>
    </>
  );
};

export default SideBar;
