import React, { useState, useMemo, useCallback, useEffect } from "react";
import { debounce } from 'lodash'
import LoadingStateComponent from "../../../components/Empty State/loadingstate";
import EmptyStateComponent from "../../../components/Empty State/emptystate";
import SearchComponent from "../../../components/search";
import PaginationComponent from "../../../components/Pagination/Pagination";
import { useDispatch, useSelector } from "react-redux";
import { PaymentTable } from "../../../redux/SalaryAdvance/advanceAction";
import ModalComponent from "../../../components/Modals/modal";
import { paySalaryAdvance } from "../../../redux/Employee/employeeAction";

const SalaryAdvancePaymentTable = () => {
  const [typeID, setTypeID] = useState(1);
  // handle search
  const [searchValue, setSearchValue] = useState('');
  //pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  //handle modal
  const [singlePayment, setSinglePayment] = useState({});
  const [showModal, setShowModal] = useState(false);

  const handlePaySalaryAdvance = () => {
    setShowModal(false)
    dispatch(paySalaryAdvance({
      id: singlePayment.id,
    }))
  }


  const onPageSizeChange = (pageSize) => {
    setPageSize(pageSize);
    dispatch(PaymentTable({ status: callTypes[typeID].tag, page: currentPage, page_size: pageSize }));
  };

  const dispatch = useDispatch();
  const { loading, payments, total } = useSelector((state) => state?.advance)
  const { is_admin } = useSelector((state) => state?.auth)

  const callTypes = useMemo(() => ([
    {
      id: 1,
      title: 'All',
      tag: 'all'
    },
    {
      id: 2,
      title: 'Pending',
      tag: 'pending'
    },
    {
      id: 3,
      title: 'Processing',
      tag: 'processing'
    },
    {
      id: 4,
      title: 'Completed',
      tag: 'completed'
    },
    {
      id: 5,
      title: 'Failed',
      tag: 'failed'
    },
  ]), [])

  const getPaymentStatusBg = (val) => {
    let result;
    switch (val) {
      case 'FAILED':
        result = 'error-badge'
        break
      case 'PROCESSING':
        result = 'dispatched-badge'
        break
      case 'COMPLETED':
        result = 'success-badge'
        break
      case 'APPROVED':
        result = 'success-badge'
        break
      case 'PENDING':
        result = 'pending-badge'
        break
      case 'DONE':
        result = 'done-badge'
        break
      case 'REJECTED':
        result = 'error-badge'
        break
      case 'PAY':
        result = 'pay-badge'
        break
      default:
        result = 'pending-badge'
        break
    }

    return result
  }

  const getPaymentStatusText = (val) => {
    let result
    switch (val) {
      case 'PROCESSING':
        result = 'Processing'
        break
      case 'FAILED':
        result = 'Failed'
        break
      case 'PENDING':
        result = 'Pending'
        break
      case 'COMPLETED':
        result = 'Completed'
        break
      case 'APPROVED':
        result = 'Approved'
        break
      case 'REJECTED':
        result = 'Rejected'
        break
      default:
        result = 'Pending'
        break
    }
    return result
  }


  const handleSearch = useMemo(
    () =>
      debounce((val) => {
        //  api to dispatch and make the actions for search
        //console.log(val); 
      }, 750),
    [],
  )

  const handleChange = useCallback(
    (e) => {
      let val = e.target.value
      setSearchValue(val)
      handleSearch(val)
    },
    [handleSearch],
  )

  const toggleType = useCallback((id, tag) => {
    setTypeID(id)
    switch (id) {
      case 1:
        dispatch(PaymentTable({ page: 1, page_size: pageSize, approval_status: "APPROVED" }))
        break;
      case 2:
        dispatch(PaymentTable({ payment_status: tag, page: currentPage, page_size: pageSize, approval_status: "APPROVED" }))
        break;
      case 3:
        dispatch(PaymentTable({ payment_status: tag, page: currentPage, page_size: pageSize, approval_status: "APPROVED" }))
        break;
      case 4:
        dispatch(PaymentTable({ payment_status: tag, page: currentPage, page_size: pageSize, approval_status: "APPROVED" }))
        break;
      case 5:
        dispatch(PaymentTable({ payment_status: tag, page: currentPage, page_size: pageSize, approval_status: "APPROVED" }))
        break;
      default:
        dispatch(PaymentTable({ page: 1, page_size: pageSize, approval_status: "APPROVED" }))
        break;
    }
  }, [dispatch, currentPage, pageSize])

  //empty state text content
  const textContent = (id) => {
    let res;
    switch (id) {
      case 1:
        res = 'Oops! No payment(s) available'
        break;
      case 2:
        res = 'Oops! No pending payment(s) available.'
        break;
      case 3:
        res = 'Oops! No processing payment(s) available.'
        break;
      case 4:
        res = 'Oops! No completed payment(s) available.'
        break;
      case 5:
        res = 'Oops! No failed payment(s) available.'
        break;
      default:
        res = 'Oops! No payment(s) available'
        break;
    }
    return res;
  }

  useEffect(() => {
    toggleType(typeID, callTypes[typeID - 1].tag)
  }, [dispatch, callTypes, toggleType, typeID])

  return (
    <>
      <div className="mt-4 bg-white rounded-[8px]">
        <div className="p-4">
          {/* search */}
          <div className="flex gap-4 mt-4">
            <div className="w-full">
              <SearchComponent
                placeholder="Search..."
                searchValue={searchValue}
                handleChange={handleChange}
              />
            </div>
          </div>

          {/* tabs */}
          <ul className="flex mt-5 gap-5 items-center">
            {callTypes.map((item) => (
              <li
                key={item.id}
                onClick={() => toggleType(item.id, item.tag)}
                className={
                  typeID === item.id
                    ? 'flex gap-1 items-center border-b-2 border-primary pb-1 cursor-pointer'
                    : 'flex gap-2 items-center pb-1 cursor-pointer'
                }
              >
                <p
                  className={
                    typeID === item.id
                      ? 'text-primary text-sm font-semibold'
                      : 'text-[#96A397] text-sm font-semibold'
                  }
                >
                  {item.title}
                </p>
              </li>
            ))}
          </ul>
        </div>
        {/* Table  */}
        <div className="overflow-x-auto relative">
          <table className="w-full text-left">
            <thead className="text-primary text-sm border-b-[#F7F7F7] border-b-2">
              <tr>
                <th scope="col" className="px-4 py-4 font-semibold ">
                  Date
                </th>
                {is_admin && <th scope="col" className="px-4 py-4 font-semibold">
                  Employee ID
                </th>}
                {is_admin && <th scope="col" className="px-4 py-4 font-semibold">
                  Employee Name
                </th>}
                <th scope="col" className="px-4 py-4 font-semibold">
                  Amount Requested
                </th>
                <th scope="col" className="px-4 py-4 font-semibold">
                  Reason for Request
                </th>
                <th scope="col" className="px-4 py-4 font-semibold">
                  Approval Status
                </th>
                <th scope="col" className="px-4 py-4 font-semibold">
                  Payment Status
                </th>
                {(typeID === 2 || typeID === 5) && <th scope="col" className="px-4 py-4 font-semibold">
                  Action
                </th>}
              </tr>
            </thead>
            {!loading && (
              <tbody>
                {payments.length > 0 && payments.map((item) => (
                  <tr
                    key={item.id}
                    onClick={() => { setSinglePayment(item); setShowModal(true) }}
                    className="bg-white text-sm cursor-pointer hover:bg-[#EDF7EE]"
                  >

                    <td className="py-4 px-4 text-[#5C715E]">
                      {payments.length > 0 && item.date_created ? new Date(item.date_created).toLocaleDateString() : "N/A"}
                    </td>
                    {is_admin && <td className="py-4 px-4 text-[#5C715E]">
                      {payments.length > 0 && item.employee?.employee_id ? item.employee?.employee_id : "N/A"}
                    </td>}
                    {is_admin && <td className="py-4 px-4 text-[#5C715E]">
                      {payments.length > 0 && item.employee?.employee_name ? item.employee?.employee_name : "N/A"}
                    </td>}
                    <td className="py-4 px-4 text-[#5C715E]">
                      {payments.length > 0 && item.amount ? item.amount?.toLocaleString() : "N/A"}
                    </td>
                    <td className="py-4 px-4 text-[#5C715E]">
                      {payments.length > 0 && item.request_reason ? `${item.request_reason.substring(0, 50)}...` : "N/A"}
                    </td>
                    <td className="py-4 px-4 text-[#5C715E]">
                      <div className="flex">
                        <div className={getPaymentStatusBg(item.approval_status)}>
                          <p>{getPaymentStatusText(item.approval_status)}</p>
                        </div>
                      </div>
                    </td>
                    <td className="py-4 px-4 text-[#5C715E]">
                      <div className="flex">
                        <div className={getPaymentStatusBg(item.status)}>
                          <p>{getPaymentStatusText(item.status)}</p>
                        </div>
                      </div>
                    </td>
                    {(typeID === 2) && <td className="py-4 px-4 text-[#5C715E]">
                      <div className="px-2 py-1 bg-[#2b8c34] rounded justify-start items-center gap-0.5 inline-flex">
                        <div className="text-white text-xs font-medium font-['Urbanist'] leading-[18px]">Pay Advance</div>
                      </div>
                    </td>}
                    {(typeID === 5) && <td className="py-4 px-4 text-[#5C715E]">
                      <div className="px-2 py-1 bg-[#2b8c34] rounded justify-start items-center gap-0.5 inline-flex">
                        <div className="text-white text-xs font-medium font-['Urbanist'] leading-[18px]">Retry Payment</div>
                      </div>
                    </td>}

                  </tr>
                ))
                }
              </tbody>
            )}
          </table>
          {loading &&
            <LoadingStateComponent />
          }
        </div>
      </div>
      {!loading && payments.length < 1 && (
        <EmptyStateComponent textContent={textContent(typeID)} />
      )}
      {!loading && payments.length > 0 ? (
        <PaginationComponent
          page={currentPage}
          onPageChange={(page) => setCurrentPage(page)}
          pageSize={pageSize}
          totalItems={total}
          pageSizeShow
          onPageSizeChange={(pageSize) => onPageSizeChange(pageSize)}
        />
      ) : ("")}
      {(typeID === 2 || typeID === 5) && <ModalComponent show={showModal} showModal={() => setShowModal(false)} title="Process Payment">
        <div className="mt-2"><span className="text-[#5c715e] text-sm font-medium font-['Urbanist'] leading-tight">Are you sure you want to approve the salary advance request of </span><span className="text-[#5c715e] text-sm font-bold font-['Urbanist'] leading-tight">{" "}{singlePayment?.amount}{" "}</span><span className="text-[#5c715e] text-sm font-medium font-['Urbanist'] leading-tight">{" "}from</span><span className="text-[#5c715e] text-sm font-bold font-['Urbanist'] leading-tight">{" "}{singlePayment?.employee?.employee_name}</span><span className="text-[#5c715e] text-sm font-medium font-['Urbanist'] leading-tight">?</span></div>
        <div className="pt-[32px] flex justify-end gap-[40px] items-center mb-[12px]">
          <button
            className="text-sm text-medium leading-5 text-[#5C715E]"
            onClick={() => { setShowModal(false) }}>No</button>
          <button
            className={`outline-0 bg-primary rounded-[8px] w-[62px] h-[40px] text-sm text-medium leading-5 text-white`}
            onClick={() => { handlePaySalaryAdvance(); setShowModal(false) }}
          >
            Yes
          </button>

        </div>
      </ModalComponent>}
    </>
  );
};

export default SalaryAdvancePaymentTable;
